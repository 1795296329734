import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { findSoucheOrderApi, findSoucheOrderOnlineApi } from "../Redux/Slices/Orders/OrderSlice";
import { OrderType } from "../Types/Entites/Orders/OrderType";
import * as XLSX from "xlsx";

export const calculTTc = (prixht: number, tva: number) => {
  return prixht + (prixht * tva) / 100;
};

export const calculHt = (prixttc: number, tva: number) => {
  return (100 * prixttc) / (100 + tva);
};

export const calculTTC_Remise = (
  prix_ttc: number,
  quantite: number,
  remise: number
) => {
  const totalBeforeRemise = quantite * prix_ttc;
  const remiseAmount = (totalBeforeRemise * remise) / 100;
  const totalAfterRemise = totalBeforeRemise - remiseAmount;

  const round = (value: number, decimalPlaces: number): number => {
    const factor = 10 ** decimalPlaces;
    return Math.round(value * factor) / factor;
  };

  return round(totalAfterRemise, 3);
};

export const formadate = (date: any) => {
  if(date){
    const inputDate = new Date(date);

    const day = inputDate.getDate().toString().padStart(2, "0");
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const year = inputDate.getFullYear();
  
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }else{
    return ""
  }
 
};

export const formatdate = (date: any) => {
  const inputDate = new Date(date);

  const day = inputDate.getDate().toString().padStart(2, "0");
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const year = inputDate.getFullYear();

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
export const generateRandomEAN = (length: any) => {
  const randomDigits = Array.from({ length }, () =>
    Math.floor(Math.random() * 10)
  ).join("");
  return calculateEANCheckDigit(randomDigits, length);
};

const calculateEANCheckDigit = (digits: any, length: any) => {
  if (digits.length !== length) {
    throw new Error(`Invalid number of digits for EAN-${length}`);
  }

  let sum = 0;
  for (let i = 0; i < digits.length; i++) {
    const digit = parseInt(digits[i], 10);
    sum += i % 2 === 0 ? digit : digit * 3;
  }

  const checkDigit = (10 - (sum % 10)) % 10;

  return `${digits}${checkDigit}`;
};
export const areArraysEqual = (arr1: any, arr2: any) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};
export const handleKeyPress = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const charCode = event.which || event.keyCode;

  // Allow only numeric characters (0-9), period (.), and minus sign (-)
  if ((charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) {
    event.preventDefault();
  }

  // Ensure that only one period is allowed
  const inputText = event.currentTarget.value;
  if (charCode === 46 && inputText.indexOf(".") !== -1) {
    event.preventDefault();
  }

  // Ensure that only one minus sign is allowed at the beginning
  if (charCode === 45 && inputText.indexOf("-") !== -1) {
    event.preventDefault();
  }
};
export const formatDateAndTime = (timestamp: Date) => {
  const dateObject = new Date(timestamp);

  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");

  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");
  const seconds = dateObject.getSeconds().toString().padStart(2, "0");

  const formattedDateAndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateAndTime;
};
export const handleKeyPress_number = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const charCode = event.which || event.keyCode;

  // Allow only numeric characters (0-9), period (.), and minus sign (-)
  if ((charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault();
  }

  // Ensure that only one period is allowed
  const inputText = event.currentTarget.value;
  if (charCode === 46 && inputText.indexOf(".") !== -1) {
    event.preventDefault();
  }
};
export const formatTime = ({ date }: { date: string }) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Date(date).toLocaleTimeString(undefined, options);
};
export const ajouterDureeToDate = (dateString: any, dureeEnJours: any) => {
  var date = new Date(dateString);
  date.setDate(date.getDate() + dureeEnJours);
  return date.toISOString();
};

export const calculateDiscountedPrice = (
  originalPrice: number,
  discountPercentage: number
) => {
  let discountAmount = (originalPrice * discountPercentage) / 100;
  let discountedPrice = originalPrice - discountAmount;
  return discountedPrice;
};

export const calculateRemise = (item: any) => {
  let montantRemiseArticle = 0;
  let montantRemiseClient = 0;
  let totalRemise = 0;

  const finalQte = item.quantity;
  let netRemise = finalQte * item.prix_unitaire;

  if (item.remise > 0) {
    montantRemiseArticle = (finalQte * item.prix_unitaire * item.remise) / 100;
    netRemise -= montantRemiseArticle; // Apply article discount
  }

  if (item.remiseClient > 0) {
    montantRemiseClient = (netRemise * item.remiseClient) / 100;
  }

  totalRemise = montantRemiseArticle + montantRemiseClient;
  return { totalRemise, montantRemiseArticle, montantRemiseClient };
};

export const maxRemise = (list: any) => {
  const maxRemise = list?.reduce((max: any, remise: any) => {
    return remise?.percentage > max?.percentage ? remise : max;
  }, list[0]);

  return maxRemise?.percentage;
};

export const sumGratuiteQuantity = (list: any) => {
  if (Array.isArray(list)) {
    const sum = list.reduce(
      (accumulator: any, currentValue: any) =>
        accumulator + currentValue.quantity,
      0
    );
    return sum;
  }
};

export const formatFloat = (number: any) => {
  if (number) {
    if (typeof number === "string") {
      number = parseFloat(number);
    }

    let formattedNumber = number.toString();
    if (formattedNumber.indexOf(".") !== -1) {
      formattedNumber = parseFloat(number).toFixed(3);
    } else {
      formattedNumber += ".000";
    }

    return formattedNumber;
  }
};

export const generateOrderNumber = (orders: OrderType[]) => {
  let uniqueId: string;
  const existingOrderNumbers = orders?.map((order) => order.order_number);
  do {
    uniqueId = "#" + (Math.floor(Math.random() * 90000) + 10000).toString();
  } while (
    existingOrderNumbers?.some((orderNumber) => orderNumber === uniqueId)
  );
  return uniqueId;
};


export const generateUniqueCode = (list: any[]) => {
  let uniqueId: string;
  const existingItems = list.map((item) => item.code);
  do {
    uniqueId = (Math.floor(Math.random() * 90000) + 10000).toString();
  } while (existingItems.some((code) => code === uniqueId));
  return uniqueId;
};
export const exportExcel = (
  namefile: string,
  nomcfeuille: string,
  list: any[]
) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(list);

  XLSX.utils.book_append_sheet(wb, ws, nomcfeuille);
  XLSX.writeFile(wb, namefile);
}



export const numberToWords = (amount: number): string => {
  const ones: string[] = [
    "", "Un", "Deux", "Trois", "Quatre", "Cinq", "Six", "Sept", "Huit", "Neuf", 
    "Dix", "Onze", "Douze", "Treize", "Quatorze", "Quinze", "Seize", "Dix-sept", 
    "Dix-huit", "Dix-neuf"
  ];

  const tens: string[] = [
    "", "", "Vingt", "Trente", "Quarante", "Cinquante", "Soixante", "Soixante-dix", "Quatre-vingts", "Quatre-vingt-dix"
  ];

  const thousands: string[] = ["", "Mille", "Million", "Milliard", "Billion"];

  function convert(num: number): string {
    if (num === 0) return "";

    if (num < 20) return ones[num];

    if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + ones[num % 10] : "");

    if (num < 1000) return (num === 100 ? "Cent" : ones[Math.floor(num / 100)] + " Cent") + (num % 100 !== 0 ? " " + convert(num % 100) : "");

    let index = 0;
    let result = "";
    
    while (num > 0) {
      if (num % 1000 !== 0) {
        result = convert(num % 1000) + " " + thousands[index] + (result ? " " + result : "");
      }
      num = Math.floor(num / 1000);
      index++;
    }
    
    return result.trim();
  }

  // Split the amount into dinars and millimes
  const [integerPart, decimalPart] = amount.toString().split(".");

  // Convert the dinars part
  let result = convert(Number(integerPart)) + " dinars";

  // Convert the millimes part (if exists and is not zero)
  if (decimalPart) {
    const millimes = decimalPart.padEnd(3, '0').slice(0, 3); // Ensure millimes are 3 digits
    if (millimes !== "000") {
      const millimesInWords = convert(Number(millimes));
      result += " et " + millimesInWords + " millimes";
    }
  }

  // Handle the case of exactly zero millimes (no "et millimes" part)
  if (decimalPart === undefined || decimalPart === "000") {
    result += " et zéro millimes";
  }

  return result.trim();
};

 export  const NextOrderStatus =(status:any)=>{
  switch (status) {
    case 'En cours':
      return 'En livraison'; // No need for break because the function returns here.
  
    case 'En livraison':
      return 'Retour'; // Similarly, return ends execution here.
  
    default:
      return 'Status inconnu'; // Optionally handle the default case if no match is found
  }
  
   
 }
 export const statusList = [
  { status: "Tout", label: "Tout" },
  { status: "Payée", label: "Payée" },
  { status: "En cours", label: "En cours" },
  { status: "En livraison", label: "En livraison" },
  { status: "En attente", label: "En attente" },
  { status: "Nouvelle", label: "Nouvelle" },
  { status: "demande Valide", label: "demande Valide" },
  { status: "demande Invalide", label: "demande Invalide" },
  { status: "demande", label: "demande" }
];
export const findSoucheCommande =  async(societe_id:any,dispatch:any)=>{
  if(societe_id) 
    {
     const respsouche = await  dispatch(findSoucheOrderApi(societe_id))
       return respsouche.payload
     
    }
 }
 export const findSoucheCommandeOnline =  async(societe_id:any,dispatch:any)=>{
  if(societe_id) 
    {
     const respsouche = await  dispatch(findSoucheOrderOnlineApi(societe_id))
       return respsouche.payload
     
    }
 }
 export const getLongLine=(text:any, maxLength:any)=> {
  if(text){
    const lines = text.split("\n"); // Diviser le texte en lignes
    for (const line of lines) {
      if (line.length > maxLength) {
        return line; // Retourne la première ligne trop longue
      }
    }
  }
 
  return null; // Aucune ligne ne dépasse la limite
}
export function compareDates(datefin:any, datedebut:any) {
  // Convertir les dates en objets Date si elles sont en format chaîne
  const dfin = new Date(datefin);
  const ddebut = new Date(datedebut);

  // Vérifier si date1 < date2
  if (dfin < ddebut) {
    ToastWarning("La date de fin ne peut pas être antérieure à la date de début.");
  } 
}

import React from "react";

export default function FiltreListDemandeRemise({
  t,
  listOragnisation,
  filtre,
  setFlitre,
  listUser,
  handleFind
}: any) {
  return (
    <div className=" row  bg-white" style={{ padding: "20px" }}>
      <div className="col-3">
        <label>{t("Organisation")}</label>
        <select
          className="form-control "
          value={filtre.organistaion}
          onChange={(e) => {
            setFlitre({
              ...filtre,
              organistaion: e.target.value,
            });
          }}
        >
          {listOragnisation &&
            listOragnisation.map((item: any, index: number) => (
              <option key={index} value={item?._id}>
                {item?.nom}
              </option>
            ))}
        </select>
      </div>
      <div className="col-3">
        <label>{t("Demandeur")}</label>
        <select
          className="form-control "
          value={filtre.demandeur}
          onChange={(e) => {
            setFlitre({
              ...filtre,
              demandeur: e.target.value,
            });
          }}
        >
          {" "}
          <option value={'tout'}>
            {t('choisir demandeur')}
          </option>
          {listUser &&
            listUser.map((item: any, index: number) => (
              <option key={index} value={item?._id}>
                {item?.nom +' ' +(item.prenom || "")}
              </option>
            ))}
        </select>
      </div>

      <div className="col-3">
        <label>{t("Etat")}</label>
        <select
          className="form-control "
          value={filtre.valide}
          onChange={(e) => {
            setFlitre({
              ...filtre,
              valide: e.target.value,
            });
          }}
        >
          <option value={"tout"}>{t(" Tout")}</option>
          <option value={"En cours"}>{t("En cours")}</option>
          <option value={"Valide"}>{t("Valide")}</option>
          <option value={"Non Valide"}>{t("Non Valide")}</option>
        </select>
      </div>

      <div className="col-1 mt-3 fs-25 ">
        {" "}
        <i className="fas fa-search" onClick={handleFind}></i>
      </div>
    </div>
  );
}

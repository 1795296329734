import React, { useEffect, useState, useTransition } from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ListMouvementsCaisse,
  findTypeMouvementByOrganisationApi,
  listTypeMouvements,
  saveMouvementsCaisseApi,
} from "../../../_App/Redux/Slices/mouvementCaisse/MouvementCaisseSlice";
import {
  SelectedOrganisationId,
  selectJournalCaisse,
  selectUser,
  selectedSessionJournal,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ApportRetraitCaisse = ({ ShowModal, setShowModal }: any) => {
  const dispatch = useAppDispatch();
  const typeMouvements = useAppSelector(listTypeMouvements);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
    const societe = useAppSelector(societeId);
  
  const [selectedType, setSelectedType] = useState<any>();
  const [montant, setMontant] = useState<any>();
  const [motif, setMotif] = useState<any>();
  const user = useAppSelector(selectUser);
  const session = useAppSelector(selectedSessionJournal);

  const journal = useAppSelector(selectJournalCaisse);
  const mouvementsCaisse = useAppSelector(ListMouvementsCaisse);
  const selectedSession = useAppSelector(selectedSessionJournal);
  const { t } = useTranslation();

  function generateMouvementNumber(): string {
    let uniqueId: string;
    const existingOrderNumbers = mouvementsCaisse.map(
      (item) => item.operation_code
    );
    do {
      uniqueId = "#" + (Math.floor(Math.random() * 90000) + 10000).toString();
    } while (
      existingOrderNumbers.some((operationCode) => operationCode === uniqueId)
    );

    return uniqueId;
  }
  const saveMouvementCaisse = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (montant && motif && selectedType) {
      dispatch(
        saveMouvementsCaisseApi({
          operation_code: generateMouvementNumber(),
          caisse_code: "someCode",
          order_id: null,
          montant: montant,
          date: new Date(),
          user_id: user?._id,
          journal_id: journal?._id,
          session_id: session?._id,
          payment_method: "Cash",
          organisation_id: currentOrganisation,
          commentaire: motif,
          type_mouvement_id: selectedType._id,
        })
      );
      setShowModal(false);
    } else {
      toast.warning("please fill all the required fields !");
    }
  };

  useEffect(() => {
    if (currentOrganisation)
      dispatch(findTypeMouvementByOrganisationApi(currentOrganisation));
  }, [currentOrganisation]);
  useEffect(() => {
    // console.log(selectedType);
  }, [selectedType]);

  if (!selectedSession || selectedSession.cloture) {
    return (
      <Modal show={ShowModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Alerte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="message-container">
            <p>
              Vous ne pouvez pas effectuer de mouvements de caisse. Aucune
              session journal ouverte.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary-app"
            onClick={() => setShowModal(false)}
          >
            Fermer
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal show={ShowModal} onHide={() => setShowModal(false)}>
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title" id="addCategorieLabel">
            {" "}
            Apport / Retrait caisse
          </span>
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            setShowModal(false);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <form onSubmit={saveMouvementCaisse}>
        <Modal.Body>
          <nav id="payment-block">
            <ul className="menu-payments">
              {typeMouvements &&
                typeMouvements.map((item: any) => {
                  if (item.libelle !== "Vente")
                    return (
                      <li className="menu" key={item._id}>
                        <a
                          href="#uiKit"
                          data-active="false"
                          className="menu-toggle"
                          onClick={() => setSelectedType(item)}
                          style={{
                            backgroundColor:
                              selectedType && selectedType._id === item._id
                                ? "lightblue" // Change to the desired background color
                                : "",
                          }}
                        >
                          <div className="base-menu">
                            <div className="base-icons">
                              <i className="fas fa-money-check-alt"></i>
                            </div>
                            <span>{item.libelle}</span>
                          </div>
                        </a>
                      </li>
                    );
                })}
              {/* <li className="menu">
                <a href="#uiKit" data-active="false" className="menu-toggle">
                  <div className="base-menu">
                    <div className="base-icons">
                      <i className="fas fa-plus"></i>
                    </div>
                  </div>
                </a>
              </li> */}
            </ul>
          </nav>

          <div className="row"> 
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="libelle">{t("amount")}</label>
                <input
                  className="form-control mb-2"
                  type="text"
                  id="montant"
                  onChange={(e) => setMontant(e.currentTarget.value)}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="libelle">{t("Motif")}</label>
                <input
                  className="form-control mb-2"
                  type="text"
                  id="motif"
                  onChange={(e) => setMotif(e.currentTarget.value)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="reset"
            onClick={(e) => {
              setShowModal(false);
            }}
            className="btn btn-primary-app"
          >
            Annuler
          </button>
          <button type="submit" className="btn btn-primary-app">
            Enregistrer
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ApportRetraitCaisse;

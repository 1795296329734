import React, { useState, ChangeEvent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { findArticleByBarCode } from "../../_App/Redux/Slices/article/articleSlice";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { ArticleType } from "../../_App/Types/Entites/ArticleType";
import {
  addToPanier,
  findDefaultClientsBySociete,
  findDefaultClientsBySocieteVerifUserApi,
} from "../../_App/Redux/Slices/Caisse/PanierSlice";
import Zoom from "react-medium-image-zoom";
import { findClientByBarcode } from "../../_App/Redux/Slices/client/clientSlice";
import { ToastContainer } from "react-toastify";
import VerifUser from "../../Components/VerifUserCode/VerifUser";
import { UserDataType } from "../../_App/Types/Forms/UserDataType";
import { OrderDataType } from "../../_App/Types/Forms/OrderDataType";
import { saveOrderApi } from "../../_App/Redux/Slices/Orders/OrderSlice";
import ClientType from "../../_App/Types/Entites/ClientType";
import {
  findSoucheCommande,
  handleKeyPress_number,
} from "../../_App/Helpers/helpers";
import { AddArticleDefectueuxApi } from "../../_App/Redux/Slices/articledefectueux/articledefectueuxSlice";
import { useTranslation } from "react-i18next";
import { findAdminBySocieteApi } from "../../_App/Redux/Slices/users/userSlice";

const DemandeRemise: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [itemsList, setItemsList] = useState<any[]>([]);
  const [searchTermClient, setsearchTermClient] = useState<string>("");

  const [selectedClient, setselectedClient] = useState<ClientType | null>(null);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [showModalUser, setshowModalUser] = useState(false);
  const [User, SetUser] = useState<UserDataType>();
  const [Admin, SetAdmin] = useState<UserDataType>();

  const { t } = useTranslation();

  useEffect(() => {
    SetUser(undefined);
    setshowModalUser(true);
  }, []);

  useEffect(() => {
    const fetchAdmin = async () => {
      if (User && User.societe_id) {
        try {
          const resp = await dispatch(findAdminBySocieteApi(User.societe_id));
          //console.log("resp",resp)
          SetAdmin(resp.payload.data);
        } catch (error) {
          console.error("Error fetching admin:", error);
        }
      }
    };

    fetchAdmin();
  }, [User]);

  const openwhatsUp = async () => {
    // cree order type demandedefaut
    if (User && User?.societe_id) {
      let client;

      if (selectedClient === null) {
        const clientdefaut = await dispatch(
          findDefaultClientsBySocieteVerifUserApi(User?.societe_id)
        );
        if (clientdefaut?.payload?._id) {
          client = clientdefaut.payload._id;
        }
      } else {
        client = selectedClient._id;
      }
      const articledataList = itemsList.map((item) => {
        return {
          article: item.article,
          quantity: 1,
          prix_unitaire: item.article.prix_vente_ttc,
          prix_total: item.article.prix_vente_ttc,
          prixNet: item.Netapayer,
          paid_quantity: 0,
          remise: item.remisedefaut,
          remiseClient: 0,
          note: item.note,
        };
      });
      const newOrder = {
        order_number: await findSoucheCommande(User?.societe_id, dispatch),
        organisation_id: User.organisations ? User.organisations[0] : "",
        user_id: User._id,
        items: articledataList,
        total: articledataList.reduce((acc, cur) => acc + cur.prix_total, 0),
        totalNet: articledataList.reduce((acc, cur) => acc + cur.prixNet, 0),
        date: new Date(),
        status: "demande",
        client_id: client,
        journal_id: undefined,
        type: "caisse",
        societe_id: User?.societe_id,
      };
      const response = await dispatch(saveOrderApi(newOrder));

      if (response.payload.success) {
        // cree defaut
        const dataarticle = itemsList.map((item) => {
          return {
            article: item.article,
            quantite: 1,
            note: item.note || "",
            image: item.image,
          };
        });

        const idOrder = response.payload.data._id;
        //  ajouter   les article au depot defecteux
        await dispatch(
          AddArticleDefectueuxApi({
            datalistAricle: dataarticle,
            organisation_id: User.organisations ? User.organisations[0] : null,
            societe_id: User?.societe_id,
            order: idOrder,
          })
        );
        const UrlValidation = `${process.env.REACT_APP_FRONT_URL}validation/${idOrder}`;
        const phoneNumber = Admin?.mobile?.replace(/\s+/g, "");

        const message = `articles avec défaut à la caisse:\n ${itemsList.map(
          (item) => `
            Code article est ${item.article?.code_article} le  prix TTC est ${item.article?.prix_vente_ttc.toFixed(3)} proposition de remise ${item.remisedefaut}% net a payer sera  ${item.Netapayer}\n Note : ${item.note}`
        )}  
         \n Url validation : ${UrlValidation}`;

        const encodedMessage = encodeURIComponent(message);

        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        window.location.href = whatsappURL;
      } else {
        ToastWarning(response.payload.message);
      }
    }
  };

  const capturePhoto = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files ? event.target.files[0] : null; // Get the selected file (photo)
    if (file) {
      // Optionally, you can preview the image or store it in state

      // Allow the user to download the image by creating a download link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = "captured_image.jpg"; // You can set the default file name here
      link.click();
    }
  };

  const handleInputChange = async (event: any) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(async () => {
      if (inputValue !== "") {
        const resultArticle = await dispatch(findArticleByBarCode(inputValue));
        if (resultArticle.payload && resultArticle.payload !== null) {
          setItemsList((prev) => [
            ...prev,
            {
              article: resultArticle.payload,
              remisedefaut: 0,
              Netapayer: resultArticle.payload.prix_vente_ttc,
            },
          ]);
          setSearchTerm("");
        } else {
          // setSearchTerm("");

          ToastWarning("l'article n'existe  ");
        }
      }
    }, 2000);

    setTimeoutId(newTimeoutId);
  };
  const handleInputClientChange = async (e: any) => {
    const value = e.target.value;
    setsearchTermClient(e.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(async () => {
      const resultclient = await dispatch(findClientByBarcode(value));

      if (
        !resultclient.payload?.success &&
        resultclient.payload &&
        resultclient.payload !== null
      ) {
        setsearchTermClient(
          resultclient.payload.nom + resultclient.payload.prenom
        );
        setselectedClient(resultclient.payload);
      } else {
        // setsearchTermClient("");

        ToastWarning("le client n'existe pas ");
      }
    }, 2000);

    setTimeoutId(newTimeoutId);
  };

  const removeItem = async (idArticle: string) => {
    const newList = itemsList.filter((item) => item.article._id !== idArticle);
    setItemsList(newList);
  };
  const onchangeRemise = (_id: string, value: number) => {
    const findIndexItem = itemsList.findIndex(
      (item) => item.article._id === _id
    );
    if (findIndexItem !== -1) {
      const copie = [...itemsList];
      const element = copie[findIndexItem];
      const newnetpayer = Number(
        (element.article.prix_vente_ttc * ((100 - value) / 100))?.toFixed(3)
      );
      copie[findIndexItem] = {
        ...itemsList[findIndexItem],
        remisedefaut: value,
        Netapayer: newnetpayer,
      };
      setItemsList(copie);
    }
  };

  const changeInformation = (name: string, value: any, _id: string) => {
    const findIndexItem = itemsList.findIndex(
      (item) => item.article._id === _id
    );
    if (findIndexItem !== -1) {
      const copie = [...itemsList];

      const element = copie[findIndexItem];

      copie[findIndexItem] = {
        ...itemsList[findIndexItem],
        [name]: value,
      };
      console.log("copie2", copie);

      setItemsList(copie);
    }
  };
  const removeClient = () => {
    setsearchTermClient("");
    setselectedClient(null);
  };
  return (
    <div className="row">
      <ToastContainer />
      <div>
        {Admin && (
          <strong>
            Admin: {Admin?.nom + " " + Admin?.prenom} {"  num tel:"}{" "}
            <input
              type="text"
              onKeyPress={handleKeyPress_number}
              className="form-control"
              value={Admin?.mobile}
              onChange={(e) => SetAdmin({ ...Admin, mobile: e.target.value })}
            />
          </strong>
        )}
      </div>
      <div>
        {User && <strong>User: {User?.nom + " " + User?.prenom}</strong>}
      </div>
      <div className="search-bar mb-5 d-flex align-items-center mt-3">
        <input
          type="text"
          className="form-control search-form-control"
          value={searchTermClient}
          onChange={handleInputClientChange}
          placeholder={"Enter product code client"}
          autoFocus
          id="code_input"
          style={{
            height: "40px",
            marginRight: "10px", // Adds space between input and button
            flex: "1", // Makes the input take the available space
          }}
        />
        {selectedClient !== null && (
          <button
            className="delete-button btn btn-danger btn-sm"
            style={{
              borderRadius: "50%",
              padding: "8px 12px",
              border: "none",
              boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
              transition: "background-color 0.3s, transform 0.3s",
            }}
            onClick={() => removeClient()}
          >
            <i className="fas fa-times"></i>
          </button>
        )}
      </div>

      <div className="search-bar mb-5">
        <input
          type="text"
          className={"form-control search-form-control ml-lg-auto"}
          value={searchTerm}
          onChange={handleInputChange}
          placeholder={"Enter product code"}
          autoFocus
          id="code_input"
          style={{ height: "40px" }}
        />
      </div>
      <div>
        <span>Articles </span>
        {itemsList.length !== 0 &&
          itemsList.map((item, index) => (
            <div className="rounded shadow-sm bg-light mb-5" key={index}>
              <div className="row mb-4 p-3 ">
                {/* Image Section */}
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <Zoom>
                    <img
                      alt=""
                      src={
                        item.image && item.image[0]
                          ? process.env.REACT_APP_API_PUBLIC_URL +
                            "article/" +
                            item.article.image[0]
                          : "/assets/assets/img/no-image.jpg"
                      }
                      className="img-fluid rounded-circle shadow"
                      style={{
                        maxWidth: "80px",
                        maxHeight: "80px",
                        objectFit: "cover",
                      }}
                    />
                  </Zoom>
                </div>

                {/* Title Section */}
                <div className="col-6 d-flex justify-content-start align-items-center">
                  <p
                    className="user-name text-truncate"
                    style={{ fontWeight: "600", fontSize: "1.1rem" }}
                  >
                    {item?.article?.titre?.fr || "No title"}
                  </p>
                </div>

                {/* Price Section */}
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <p
                    className="user-name text-truncate"
                    style={{
                      fontWeight: "600",
                      fontSize: "1.1rem",
                      color: "#007bff",
                    }}
                  >
                    {item?.article?.prix_vente_ttc.toFixed(3) || "N/A"}
                  </p>
                </div>

                {/* Discount Section */}
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <input
                    type="number"
                    value={item.remisedefaut}
                    className="form-control form-control-sm"
                    placeholder="Remise"
                    onChange={(e) =>
                      onchangeRemise(item.article?._id, Number(e.target.value))
                    }
                    style={{
                      maxWidth: "120px",
                      borderRadius: "8px",
                      border: "1px solid #ddd",
                      boxShadow: "none",
                    }}
                  />
                </div>
                <div className="col-4 d-flex justify-content-center align-items-center">
                  <span>Net a payer : {item.Netapayer}</span>
                </div>

                {/* Delete Button Section */}
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <button
                    className="delete-button btn btn-danger btn-sm"
                    style={{
                      borderRadius: "50%",
                      padding: "8px 12px",
                      border: "none",
                      boxShadow: "0px 2px 6px rgba(0,0,0,0.2)",
                      transition: "background-color 0.3s, transform 0.3s",
                    }}
                    onClick={() => removeItem(item.article?._id)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div className="row">
                <strong>Note</strong>
                <textarea
                  className={"form-control search-form-control ml-lg-auto"}
                  onChange={(e) =>
                    changeInformation("note", e.target.value, item.article?._id)
                  }
                  value={item?.note}
                />
              </div>
              <div className=" row">
                <button className="col-3">
                  <label
                    htmlFor={`camera-input-${item.article?._id}`}
                    style={{ cursor: "pointer" }}
                  >
                    {t("Ouvrir l'appareil photo")}
                  </label>
                </button>
                <input
                  className="col-3"
                  id={`camera-input-${item.article?._id}`}
                  type="file"
                  accept="image/*"
                  capture="user"
                  onChange={(e) => {
                    // capturePhoto(e);
                    changeInformation(
                      "image",
                      e.target.files ? e.target.files[0] : null,
                      item.article?._id
                    );
                  }}
                  style={{ display: "none" }}
                />
                <div className="col-3">
                  {item?.image && (
                    <Zoom>
                      <img
                        src={URL.createObjectURL(item?.image)}
                        alt="Captured"
                        width="50"
                      />
                    </Zoom>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>

      <button className="btn btn-primary" onClick={openwhatsUp}>
        Open WhatsApp with Message
      </button>

      <VerifUser
        ShowModal={showModalUser}
        setShowModal={setshowModalUser}
        societe={null}
        user={User}
        setuser={SetUser}
        setConfirmer={null}
      />
    </div>
  );
};

export default DemandeRemise;

import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { formadate } from "../../_App/Helpers/helpers";
import ProductDetailsForm from "../Products/ProductDetails/ProductDetailsForm";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  findProductByOrganisationApi,
  ListProduits,
} from "../../_App/Redux/Slices/produits/produitSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import AchatForm from "./AchatForm";
import {
  clotureDetailAchatApi,
  findFileApi,
} from "../../_App/Redux/Slices/achat/achatSlice";
import { AxiosResponse } from "axios";

import {
  SelectedOrganisations,
  selectUserId,
} from "../../_App/Redux/Slices/Auth/authSlice";

import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { DetailAchatType } from "../../_App/Types/Entites/DetailAchatType";
import { DepotsType } from "../../_App/Types/Entites/DepotsType";
export default function DetailAchatTable({
  t,
  organisation_id,
  ShowModal,
  setShowModal,
  Achat,
  ListDetailAchats,
  listdepots,
}: any) {
  const [ShowModalAchats, setShowModalAchats] = useState(false);
  const [etat, setetat] = useState(false);

  const [listdetailachat, setlistdetailachat] = useState([]);
  const listproduits = useAppSelector(ListProduits);
  const selectedOrganisations = useAppSelector(SelectedOrganisations);

  const SelectUserId = useAppSelector(selectUserId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (organisation_id) {
      dispatch(findProductByOrganisationApi(organisation_id));
    }
  }, [dispatch, organisation_id, SelectUserId]);
  useEffect(() => {
    if (ListDetailAchats && ListDetailAchats.length !== 0) {
      const newDetailAchatlist = ListDetailAchats.map((element: any) => {
        const findproduit = listproduits.find(
          (produit) => produit._id === element.product_id
        );
        const produit = findproduit ? findproduit : null;
        const finddepots = listdepots.find(
          (depot: DepotsType) => depot._id === element.depots_id
        );

        const depot = finddepots ? finddepots.libelle : null;

        return {
          ...element,
          produit,
          depot,
        };
      });
      setlistdetailachat(newDetailAchatlist);
    } else {
      setlistdetailachat([]);
    }
  }, [ListDetailAchats, listproduits]);

  const handelupdate = () => {
    setShowModalAchats(true);
  };

  const handelgetfile = async () => {
    try {
      // Dispatch l'action pour récupérer le fichier
      const achat_file = Achat.file;
      if (achat_file && achat_file.length !== 0) {
        const action = await dispatch(findFileApi(achat_file));

        // Vérifiez si l'action est accomplie
        if (findFileApi.fulfilled.match(action)) {
          const axiosResponse: AxiosResponse = action.payload;

          // Créez un Blob à partir de la réponse
          const blob = new Blob([axiosResponse.data], {
            type: axiosResponse.headers["content-type"],
          });

          // Créez un object URL pour le Blob
          const objectUrl = URL.createObjectURL(blob);

          // Ouvrez un nouvel onglet avec le PDF
          const newTab = window.open(objectUrl, "_blank");
          if (!newTab) {
            ToastWarning("Popup blocker prevented opening a new tab.");
          }
        } else if (findFileApi.rejected.match(action)) {
          // Gérez le cas où l'action est rejetée
          const error = action.payload;
          console.log(error);
          ToastWarning("error lors de traitement d action ");
        }
      } else {
        ToastWarning("l'achat ne contient pas de document");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handelcloturer = async (detailachat: DetailAchatType) => {
    const response = await dispatch(clotureDetailAchatApi(detailachat));

    if (response.payload.success) {
      ToastSuccess(t(response.payload.message));
    } else {
      ToastWarning(response.payload.message);
    }
    window.location.reload();
  };
  return (
    <>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-1"
        dialogClassName="modal-70w"
        backdropClassName="modal-backdrop-1"
      >
        <Modal.Header closeButton>
          <Modal.Title className="c-primary"> {t("Detail Achat")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container mt-4 ">
            <div className="row mb-3 ">
              <div className="col-md-2 ">
                <div className="form-group equal-height">
                  <div className="row mb-2">
                    <label htmlFor="reference_document">
                      {t("Ref-Document")}
                    </label>
                    <span>{Achat.reference_document}</span>
                  </div>

                  <div className="row mb-2">
                    <label htmlFor="fournisseur_id ">{t("Fournisseur")}</label>
                    <span> {Achat.fournisseur.libelle}</span>
                  </div>
                  <div className="row mb-2">
                    <label htmlFor="fournisseur_id ">{t("Organisation")}</label>
                    <span>
                      {" "}
                      {
                        selectedOrganisations?.find(
                          (item: any) => item._id === Achat.organisation_id
                        )?.nom
                      }
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-2 ml-2 ">
                <div className="form-group equal-height">
                  <div className="row mb-2">
                    <label htmlFor="date_document">{t("date document")}</label>
                    <span>{formadate(Achat.date_document)}</span>{" "}
                  </div>
                  <div className="row mb-2">
                    {" "}
                    <label htmlFor="date_livraison">
                      {t("date livraison")}
                    </label>
                    <span> {formadate(Achat.date_livraison)}</span>
                  </div>
                  <div className="row mn-2">
                    {" "}
                    <label>{t("depots")}</label>
                    <span> {Achat.depotachat}</span>
                  </div>
                </div>
              </div>

              <div className="col-md-1 ">
                <div className="form-group equal-height">
                  <div className="row mb-2">
                    {" "}
                    <label htmlFor="RPied">{t("R.Pied")}</label>
                  </div>

                  <div className="row mb-2">
                    {" "}
                    <label htmlFor="REspece">{t("R.Espece")}</label>
                  </div>

                  <div className="row">
                    {" "}
                    <label htmlFor="RFacture">{t("R.Facture")}</label>
                  </div>
                </div>
              </div>

              <div className="col-md-1  ml-5">
                <div className="form-group equal-height">
                  <div className="row mb-2">{Achat.RPied}</div>
                  <div className="row mb-2">{Achat.REspece}</div>
                  <div className="row mb-2">{Achat.RFacture}</div>
                </div>
              </div>

              <div className="col-md-2 ">
                <div className="form-group equal-height">
                  <label>
                    {t("Total HT : ")}
                    <span>{Achat.total_ht.toFixed(3)}</span>
                  </label>

                  <label>
                    {t("Total TTC :")}
                    <span>{Achat.total_ttc.toFixed(3)}</span>
                  </label>

                  <label>
                    {t("Total Net TTC :")} <span>{Achat.total_net_ttc.toFixed(3)}</span>
                  </label>

                  <label>
                    {t("timber :")} <span>{Achat?.timber}</span>
                  </label>

                  <label>
                    {t("Total a payer : ")}
                    <span>{Achat.total_net_payer.toFixed(3)}</span>
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group ">
                  <label htmlFor="commentaire">{t("commentaire")}</label>
                  {Achat.commentaire}
                </div>
                <label onClick={handelgetfile}>
                  <i className="fas fa-file-alt fa-lg"></i>{" "}
                  <p>{t("Voir le document d'achat")}</p>
                </label>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>{t("Produit")}</th>
                    <th>{t("Depots")}</th>
                    <th>{t("Code a barre Produit")}</th>
                    <th>{t("Quantite")}</th>
                    <th>{t("Prix Achat TTC")}</th>
                    <th>{t("Prix Achat HT")}</th>
                    <th>{t("Remise")}</th>
                    <th>{t("Total Net Achat TTC")}</th>
                    <th>{t("Total Prix Achat TTC")}</th>
                    <th>{t("Total Prix Achat HT")}</th>

                    <th>{t("TVA")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {listdetailachat.map((item: any, index: number) => (
                    <tr key={index}>
                      <td>{item.type}</td>
                      <td>
                        {" "}
                        <span>
                          {item?.produit?.titre?.fr
                            ? item.produit?.titre.fr
                            : ""}
                        </span>
                      </td>
                      <td>{item?.depot}</td>
                      <td> {item?.produit?.code_a_barreProduit}</td>
                      <td>{item?.quantite}</td>
                      <td>{item?.prix_ttc.toFixed(3)}</td>
                      <td>{item?.prix_ht.toFixed(3)}</td>
                      <td>{item?.remise}</td>
                      <td>{item?.total_net_ttc.toFixed(3)}</td>
                      <td>{item?.total_prix_ttc.toFixed(3)}</td>
                      <td>{item?.total_prix_ht.toFixed(3)}</td>
                      <td>{item?.tva}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {!Achat.confirmer && !Achat.annuler && (
            <button
              className="btn btn-primary-app"
              onClick={() => {
                setetat(false);
                handelupdate();
              }}
            >
              {t("Modifier Achat")}
            </button>
          )}
          {Achat.confirmer && !Achat.cloture && (
            <button
              className="btn btn-primary-app"
              onClick={() => {
                setetat(true);
                handelupdate();
              }}
            >
              {t("verifier et modifier les details Achat")}
            </button>
          )}
        </Modal.Footer>
      </Modal>

      <AchatForm
        t={t}
        etat={etat}
        ShowModal={ShowModalAchats}
        setShowModal={setShowModalAchats}
        Achat={Achat}
        Detailachat={listdetailachat}
        organisation_id={organisation_id}
      />
    </>
  );
}
/* <td>                            <button onClick={()=>handelcloturer(item)} className="btn btn-primary-app">
                              cloturer{" "}
                            </button>
                      </td>*/

/*const handelselectitem = (item: any) => {
    if (Achat.cloture === false) {
      ToastWarning(
        "Pour ajouter des details produits, vous devez confirmer l'achat"
      );
    } else {
      setproductid(item.product_id);
      setdetailachat(item);
      setSShowModalProductDetail(true);
    }
  };*/

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import _ from "lodash";
import { RootState } from "../../store";
/**
 * create clients function
 * @private
 */
export const findDemandeRemiseApi = createAsyncThunk(
  "demandeRemise/findDemandeRemiseApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("demandeRemise/findDemandeRemiseApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
interface ClientState {
    listDemandeRemise: any[];
  
  }
  
  const initialState: ClientState = {
    listDemandeRemise: [],

  };
  
  export const demandeRemiseSlice = createSlice({
    name: "demandeRemiseSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(findDemandeRemiseApi.fulfilled, (state, action) => {
        state.listDemandeRemise =action.payload;
      });
  
    
    },
  });
  export const ListDemandeRemise = (state: RootState) => state.demandesremises.listDemandeRemise;
  
  
  export default demandeRemiseSlice.reducer;
import React, { useEffect, useState } from 'react'

import { useAppSelector } from '../../../_App/Redux/hooks'
import { findproduitbyarticleIdApi, ListProduits } from '../../../_App/Redux/Slices/produits/produitSlice'
import { Modal } from 'react-bootstrap'


export default function ArticleFournisseur({t,showModal,setshowModal,refproduct ,dispatch , idarticle }: any) {
   
    const list_produitArticle = useAppSelector(ListProduits)

      //useEffect
  useEffect(() => {
    if(idarticle){
         dispatch(findproduitbyarticleIdApi(idarticle))

  
    }
  }, [idarticle]);


  return (
    <Modal
    show={showModal}
    onHide={() => setshowModal(false)}
    dialogClassName="modal-70w"
  >
    <Modal.Header closeButton>
      <Modal.Title className="c-primary">
        <div className="row">
          <div className="col-md-12"> {t(" Article")}</div>
        </div>
      </Modal.Title>
    </Modal.Header>

   
      <Modal.Body>
      <table className="table table-bordered">
    <thead>
      <tr>
        <th>{t('Produit')}</th>
        <th>{t('Fournisseur')}</th>
        <th>{t('Prix achat ht')}</th>
        <th>{t('Prix achat ttc')}</th>
   



      </tr>
    </thead>
    <tbody>
      {list_produitArticle.map((item: any) => (
        <tr key={item._id} >
     
          <td className={item._id === refproduct ? 'highlighted-row' : ''}> {item?.titre.fr}</td>
          <td className={item._id === refproduct ? 'highlighted-row' : ''}> {item?.fournisseurs?.libelle}</td>
          <td className={item._id === refproduct ? 'highlighted-row' : ''}> {item?.prix_achat_ht.toFixed(3)}</td>  
          <td className={item._id === refproduct ? 'highlighted-row' : ''}> {item?.prix_achat_ttc.toFixed(3)}</td>
          
        
        </tr>
      ))}
    </tbody>
  </table>
      </Modal.Body>

 
  </Modal>
  
  )
}

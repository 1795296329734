import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ListMouvementsCaisse,
  deleteTypeMouvement,
  findTypeMouvementByOrganisationApi,
  listTypeMouvements,
  saveMouvementsCaisseApi,
  saveTypeMouvemetApi,
} from "../../../_App/Redux/Slices/mouvementCaisse/MouvementCaisseSlice";
import {
  SelectedOrganisationId,
  selectJournalCaisse,
  selectUser,
  selectedSessionJournal,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const MouvementCaisse = () => {
  const dispatch = useAppDispatch();
  const typeMouvements = useAppSelector(listTypeMouvements);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const societe = useAppSelector(societeId);

  const [selectedType, setSelectedType] = useState<any>();
  const [libelle, setLibelle] = useState<any>();
  const [selectedSens, setSelectedSens] = useState<any>("");
  const user = useAppSelector(selectUser);
  const session = useAppSelector(selectedSessionJournal);
  const [ShowModal, setShowModal] = useState(false);

  const journal = useAppSelector(selectJournalCaisse);
  const mouvementsCaisse = useAppSelector(ListMouvementsCaisse);
  const selectedSession = useAppSelector(selectedSessionJournal);
  const { t } = useTranslation();
  const handleDeleteType = async (id: string) => {
    Swal.fire({
      title: "Voulez vous vraiment vider ce raccourci ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(deleteTypeMouvement(id));
      }
    });
  };

  const saveMouvementCaisse = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (libelle && selectedSens) {
      dispatch(
        saveTypeMouvemetApi({
          libelle: libelle,
          sens: selectedSens,
          organisation_id: currentOrganisation,
        })
      );
      setShowModal(false);
    } else {
      toast.warning("please fill all the required fields !");
    }
  };

  useEffect(() => {
    if (currentOrganisation)
      dispatch(findTypeMouvementByOrganisationApi(currentOrganisation));
  }, [currentOrganisation]);
  useEffect(() => {
    // console.log(selectedType);
  }, [selectedType]);
  return (
    <div>
      <nav id="payment-block">
        <ul className="menu-payments">
          {typeMouvements &&
            typeMouvements.map((item: any) => {
              if (item.libelle !== "Vente")
                return (
                  <li className="menu" key={item._id}>
                    <span
                      className="quantity-badge"
                      onClick={() => handleDeleteType(item._id)}
                    >
                      {" "}
                      <i className="fas fa-times"></i>
                    </span>
                    <a
                      href="#uiKit"
                      data-active="false"
                      className="menu-toggle"
                      onClick={() => setSelectedType(item)}
                      style={{
                        backgroundColor:
                          selectedType && selectedType._id === item._id
                            ? "lightblue" // Change to the desired background color
                            : "",
                      }}
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          <i className="fas fa-money-check-alt"></i>
                        </div>
                        <span>{item.libelle}</span>
                      </div>
                    </a>
                  </li>
                );
            })}
          <li className="menu">
            <a
              href="#uiKit"
              data-active="false"
              className="menu-toggle"
              onClick={() => setShowModal(true)}
            >
              <div className="base-menu">
                <div className="base-icons">
                  <i className="fas fa-plus"></i>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </nav>

      <Modal show={ShowModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>
            <span className="modal-title" id="addCategorieLabel">
              {" "}
              Ajouter un type de mouvement{" "}
            </span>
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              setShowModal(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form onSubmit={saveMouvementCaisse}>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="libelle">{t("Libelle")}</label>
                  <input
                    className="form-control mb-2"
                    type="text"
                    id="libelle"
                    onChange={(e) => setLibelle(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="sens" style={{ marginRight: "20px" }}>
                    {t("Sens :")}
                  </label>

                  <label htmlFor="credit" style={{ marginRight: "20px" }}>
                    {t("Credit")}
                  </label>
                  <input
                    type="radio"
                    checked={selectedSens === "c"}
                    onChange={(e) => setSelectedSens("c")}
                    name="custom-radio-1"
                    style={{ marginRight: "20px" }}
                  />
                  <label htmlFor="debit" style={{ marginRight: "20px" }}>
                    {t("Debit")}
                  </label>
                  <input
                    type="radio"
                    checked={selectedSens === "d"}
                    onChange={(e) => setSelectedSens("d")}
                    name="custom-radio-2"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="reset"
              onClick={(e) => {
                setShowModal(false);
              }}
              className="btn btn-primary-app"
            >
              Annuler
            </button>
            <button type="submit" className="btn btn-primary-app">
              Enregistrer
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default MouvementCaisse;

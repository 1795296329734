import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { SousCategorie } from "../../../Types/Entites/SousCategorie";
import { PaginationType } from "../../../Types/Entites/PaginationType";
import axios from "axios";
import SousCategorieDataType from "../../../Types/Forms/SousCategorieDataType";
import { SousCategorieType } from "../../../Types/Entites/SousCategorieType";
import _ from "lodash";

/**
 * create sous categorie.
 * @public
 */

export const createSousCategorieApi = createAsyncThunk(
  "souscategorie/createsousCategorieApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
         
        },
      };

      const { libelle, image, organisation_id, categorie_id, societe_id ,changeMin} =
        data;
      var body = new FormData();
      body.append("libelle[fr]", libelle.fr.toString());
      body.append("libelle[abr]", libelle.abr.toString());
      body.append('changeMin',changeMin.toString())

      if (image) {
        if (image.length > 0) {
          body.append("image", image[0]);
        }
      }
      body.append("societe_id", societe_id.toString());
      const organisation =
        organisation_id !== null ? organisation_id.toString() : organisation_id;
      body.append("organisation_id", organisation);
      const categorie =
        categorie_id && categorie_id !== null
          ? categorie_id.toString()
          : "null";

      body.append("categorie_id", categorie);
      const response = await axios.post(
        "souscategorie/createsousCategorieApi",
        body,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateSousCategorieApi = createAsyncThunk(
  "souscategorie/updateSousCategorieApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const {
        _id,
        libelle,
        description,
        image,
        organisation_id,
        categorie_id,
        societe_id,
        minimalStock,
        changeMin
      } = data;
      var body = new FormData();
      body.append("_method", "put");
      body.append('minimalStock',minimalStock.toString())
      body.append('changeMin',changeMin.toString())
      body.append("_id", _id.toString());
      body.append("libelle[fr]", libelle.fr.toString());
      body.append("libelle[abr]", libelle.abr.toString());

      body.append("societe_id", societe_id.toString());
      const organisation = (organisation_id !== null  )? organisation_id.toString() : 'null';

      body.append("organisation_id", organisation);
      if (image) {
        if (Array.isArray(image)) {
          body.append("image", image[0]);
        }
      }
      const categorie =
        (categorie_id && categorie_id !== null)
          ? categorie_id.toString()
          : "null";

      body.append("categorie_id", categorie);

      const response = await axios.put(
        "souscategorie/updateSousCategorieApi",
        body,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of sous categories.
 * @public
 */
export const findSousCategoriesApi = createAsyncThunk(
  "souscategorie/findSousCategoriesApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `souscategorie/findSousCategoriesApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of sous categories.
 * @public
 */
export const findSousCategorieByLibelleApi = createAsyncThunk(
  "souscategorie/findSousCategorieLikeLibelleApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `souscategorie/findSousCategorieLikeLibelleApi/${data.societe_id}/${data.libelle}/${data.index}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of categorie's sous categories.
 * @public
 */
export const findSousCategoriesByCategorieApi = createAsyncThunk(
  "souscategorie/findSousCategorieByCategorieApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `souscategorie/findSousCategorieByCategorieApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of categorie's sous categories.
 * @public
 */
export const findSousCategorieBySociete = createAsyncThunk(
  "souscategorie/findSousCategorieBySociete",
  async (societe: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `souscategorie/findSousCategorieBySociete/${societe}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findSousCategorieBySocieteApi = createAsyncThunk(
  "souscategorie/findSousCategorieBySocieteApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `souscategorie/findSousCategorieBySocieteApi/${data.societe}/${data.index}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get pagination.
 * @public
 */
export const updatepagination = createAsyncThunk(
  "souscategorie/updatepagination",
  async (data: PaginationType, { rejectWithValue, dispatch }) => {
    try {
      //insert in data base
      return data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface SousCategorieSate {
  listSousCategorie: SousCategorie[];
  ListCategoriSousCategories: SousCategorie[];
  listSousCategorieByCategorie: {
    [categorie_id: string]: SousCategorie[];
  };
  pagination: PaginationType;
  totalPages:any
  currentpage:any,
}
const initialPagination: PaginationType = {
  itemsPerPage: 5,
  NumberColone: 2,
};

const initialState: SousCategorieSate = {
  listSousCategorie: [],
  listSousCategorieByCategorie: {},
  pagination: initialPagination,
  ListCategoriSousCategories: [],
  totalPages:null,
  currentpage:null,
};
export const souscategorieSlice = createSlice({
  name: "souscategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findSousCategoriesApi.fulfilled, (state, action) => {
      const groupedByCategorieId = _.groupBy(action.payload, "categorie_id");
      return {
        ...state,
        listSousCategorie: action.payload,
        listSousCategorieByCategorie: groupedByCategorieId,
      };
    });
    builder.addCase(
      findSousCategoriesByCategorieApi.fulfilled,
      (state, action) => {
        const groupedByCategorieId = _.groupBy(action.payload, "categorie_id");
        return {
          ...state,
          listSousCategorieByCategorie: groupedByCategorieId,
          ListCategoriSousCategories: action.payload,
      
        }; 
      }
    );
    builder.addCase(
      findSousCategorieBySocieteApi.fulfilled,
      (state, action) => {
        const groupedByCategorieId = _.groupBy(action.payload.data, "categorie_id");
        return {
          ...state,
          listSousCategorie: action.payload.data,
          totalPages:action.payload.totalPages,
          currentpage:action.payload.currentPage,
          listSousCategorieByCategorie: groupedByCategorieId,
        };
      }
    );
    builder.addCase(
      findSousCategorieBySociete.fulfilled,
      (state, action) => {
        const groupedByCategorieId = _.groupBy(action.payload, "categorie_id");
        return {
          ...state,
          listSousCategorie: action.payload,
          
          listSousCategorieByCategorie: groupedByCategorieId,
        };
      }
    );
    builder.addCase(
      findSousCategorieByLibelleApi.fulfilled,
      (state, action) => {
        const groupedByCategorieId = _.groupBy(action.payload.data, "categorie_id");
        return {
          ...state,
          listSousCategorie: action.payload.data,
          totalPages:action.payload.totalPages,
          currentpage:action.payload.currentPage,
          listSousCategorieByCategorie: groupedByCategorieId,
        };
      }
    );
    builder.addCase(updatepagination.fulfilled, (state, action) => {
      return {
        ...state,
        pagination: action.payload,
      };
    });
  },
});

// export const { increment, decrement, incrementByAmount } = categorieSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const ListSousCategories = (state: RootState) =>
  state.souscategories.listSousCategorie;
export const ListSousCategoriesByCategorie = (state: RootState) =>
  state.souscategories.listSousCategorieByCategorie;
export const PaginationSousCategories = (state: RootState) =>
  state.souscategories.pagination;
export const CategorieSousCategories = (state: RootState) =>
  state.souscategories.ListCategoriSousCategories;
export const currentpage = (state: RootState) => state.souscategories.currentpage;
export const totalPages = (state: RootState) => state.souscategories.totalPages;
export default souscategorieSlice.reducer;

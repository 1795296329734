import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import {
  SelectedOrganisationId,
  selectJournalCaisse,
  selectedAutorisations,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import JournalCaisseEntryForm from "./OuvertureCaisseForm";
import JournalCaisseClotureForm from "./ClotureCaisseForm";
import OuvertureSessionForm from "../SessionJournal/OuvertureSessionForm";
import { selectedSessionJournal } from "../../_App/Redux/Slices/Auth/authSlice";
import ClotureSessionForm from "../SessionJournal/ClotureSessionForm";
import StatistiqueJournal from "./StatistiqueJournal";
import {
  ListJournalOrders,
  ListOrders,
  findOrdersByJournalApi,
} from "../../_App/Redux/Slices/Orders/OrderSlice";
import { Button } from "react-bootstrap";
import { OrderType } from "../../_App/Types/Entites/Orders/OrderType";
import { logoutApi } from "../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";
import { findParametragesCaisseByOrganisationApi } from "../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";

const PopupContent = styled.div`
  background: #fff;
  padding: 60px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  position: relative;
  max-width: 800px;
  margin: 0 auto;
`;
const AlertTitle = styled(Modal.Title)`
  color: red;
`;

const CustomModal = styled(Modal)`
  .modal-header {
    // background-color: #007bff;
    // color: white;
  }

  .modal-title {
    flex: 1;
  }

  .close {
    color: white;
  }

  .modal-footer {
    justify-content: flex-start;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const ActionButton = styled.button`
  padding: 50px;
  border: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  outline: none;
  width: 100%;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const OpenCaisseButton = styled(ActionButton)`
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#28bd4a")};
  color: #fff;
  margin-right: 10px;
`;

const CloseCaisseButton = styled(ActionButton)`
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#f44336")};
  color: #fff;
`;

const OpenSessionButton = styled(ActionButton)`
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#007bff")};

  color: #fff;
  margin-top: 10px;
  margin-right: 10px;
`;

const CloseSessionButton = styled(ActionButton)`
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#007bff")};

  color: #fff;
  margin-top: 10px;
`;
const StatsButton = styled(ActionButton)`
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#f8a63a;")};
  color: #fff;
  margin-top: 10px;
  width: 48%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-left: 15px;

  i {
    font-size: 28px;
    margin-left: 8px;
  }
`;
const LogoutButton = styled(ActionButton)`
  background-color: #d9534f;
  color: #fff;
  margin-top: 10px;
  width: 48%;
  height: 20px;
  cursor: pointer;
  justify-content: center;
  display: flex;

  font-size: 14px;
  align-items: center;
  i {
    font-size: 20px;
    margin-left: 8px;
  }
`;

interface CaissePopupProps {
  onCloseCaisse: () => void;
}

const HomeCaisse: React.FC<CaissePopupProps> = ({ onCloseCaisse }) => {
  const journalCaisse = useAppSelector(selectJournalCaisse);
  console.log('journalCaisse',journalCaisse)
  const dispatch = useAppDispatch();
  const autorisations = useAppSelector(selectedAutorisations);
  const canOpenJournal = autorisations?.CAN_CREATE_JOURNAL_CAISSE;
  const CanClotureJournal = autorisations?.CAN_CLOTURE_JOURNAL_CAISSE;
  const canOpenSession = autorisations?.CAN_CREATE_SESSION_JOURNAL_CAISSE;
  const canClotureSession = autorisations?.CAN_CLOTURE_SESSION_JOURNAL_CAISSE;

  const currentOrg = useAppSelector(SelectedOrganisationId);

  useEffect(() => {
    if (journalCaisse?._id) {
      dispatch(findOrdersByJournalApi(journalCaisse._id));
    }
    if (currentOrg)
      dispatch(findParametragesCaisseByOrganisationApi(currentOrg));
  }, [dispatch, journalCaisse]);

  const sessionJournal = useAppSelector(selectedSessionJournal);
  const orders = useAppSelector(ListJournalOrders);

  const [showJournalCaisseEntryForm, setShowJournalCaisseEntryForm] =
    useState(false);
  const [showJournalCaisseClotureForm, setShowJournalCaisseClotureForm] =
    useState(false);
  const [showOuvertureSessionForm, setShowOuvertureSessionForm] =
    useState(false);
  const [showClotureSessionForm, setShowClotureSessionForm] = useState(false);
  const [showStatistiqueModal, setShowStatistiqueModal] = useState(false);
  const [showPendingOrderAlert, setShowPendingOrderAlert] = useState(false);
  const [pendingOrders, setPendingOrders] = useState<OrderType[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleOpenJournalCaisseClotureForm = () => {
    const pendingOrdersList = orders.filter(
      (order) => order.status === "En attente"
    );

    if (pendingOrdersList.length > 0) {
      setPendingOrders(pendingOrdersList);
      setShowPendingOrderAlert(true);
    } else {
      setShowJournalCaisseClotureForm(true);
    }
  };

  const handleClosePendingOrderAlert = () => {
    setShowPendingOrderAlert(false);
  };

  const handleOpenClotureSessionForm = () => {
    setShowClotureSessionForm(true);
  };

  const handleOpenJournalCaisseEntryForm = () => {
    setShowJournalCaisseEntryForm(true);
  };

  const handleCloseJournalCaisseForm = () => {
    setShowJournalCaisseEntryForm(false);
    setShowJournalCaisseClotureForm(false);
  };
  const handleOpenOuvertureSessionForm = () => {
    setShowOuvertureSessionForm(true);
  };

  const handleOpenStatistiqueModal = () => {
    setShowStatistiqueModal(true);
  };

  const handleCloseStatistiqueModal = () => {
    setShowStatistiqueModal(false);
  };
  const handleOpenConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const handleLogout = async () => {
    await dispatch(logoutApi());
  };
  const { t } = useTranslation();
  return (
    <CustomModal show={true} onHide={onCloseCaisse}>
      <Modal.Header closeButton>
        <Modal.Title>Actions Caisse</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PopupContent>
          <ButtonContainer>
            <LogoutButton onClick={handleLogout}>
              {t("Déconnexion")} <i className="fas fa-power-off"></i>
            </LogoutButton>
            <StatsButton onClick={handleOpenStatistiqueModal}>
              {t("Statistiques")}
              <i className="far fa-chart-bar"></i>
            </StatsButton>
          </ButtonContainer>
          <ButtonContainer>
            {canOpenJournal && (
              <OpenCaisseButton
                onClick={handleOpenJournalCaisseEntryForm}
                disabled={journalCaisse?.cloture === false}
              >
                {t("Ouvrir journal caisse")}
              </OpenCaisseButton>
            )}
            {CanClotureJournal && (
              <CloseCaisseButton
                onClick={handleOpenConfirmationModal}
                disabled={journalCaisse?.cloture === true || !journalCaisse}
              >
                {t("Clôturer journal caisse")}
              </CloseCaisseButton>
            )}
          </ButtonContainer>
          <ButtonContainer>
            {canOpenSession && (
              <OpenSessionButton
                onClick={handleOpenOuvertureSessionForm}
                disabled={
                  !sessionJournal ||
                  sessionJournal.cloture === false ||
                  !journalCaisse ||
                  journalCaisse.cloture === true
                }
              >
                {t("Ouvrir  session journal")}
              </OpenSessionButton>
            )}
            {canClotureSession && (
              <CloseSessionButton
                onClick={handleOpenClotureSessionForm}
                disabled={
                  sessionJournal?.cloture === true || sessionJournal === null
                }
              >
                {t("Clôturer Session journal")}
              </CloseSessionButton>
            )}
          </ButtonContainer>

          {showJournalCaisseEntryForm && (
            <JournalCaisseEntryForm
              onClose={() => {
                handleCloseJournalCaisseForm();
              }}
            />
          )}

          {showJournalCaisseClotureForm && (
            <JournalCaisseClotureForm
              onClose={() => {
                handleCloseJournalCaisseForm();
              }}
            />
          )}
          {showOuvertureSessionForm && (
            <OuvertureSessionForm
              onClose={() => {
                setShowOuvertureSessionForm(false);
              }}
            />
          )}
          {showClotureSessionForm && (
            <ClotureSessionForm
              onClose={() => {
                setShowClotureSessionForm(false);
              }}
            />
          )}
          {showStatistiqueModal && (
            <StatistiqueJournal
              onClose={() => {
                setShowStatistiqueModal(false);
                onCloseCaisse();
              }}
            />
          )}
        </PopupContent>
        {showStatistiqueModal && (
          <StatistiqueJournal
            onClose={() => {
              handleCloseStatistiqueModal();
              onCloseCaisse();
            }}
          />
        )}
      </Modal.Body>
      <Modal show={showPendingOrderAlert} onHide={handleClosePendingOrderAlert}>
        <Modal.Header closeButton>
          <AlertTitle>{t("Commandes en Attente")}</AlertTitle>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("Il y a")}
            {pendingOrders.length} {t("commande(s) en attente:")}
          </p>
          <ul>
            {pendingOrders.map((order) => (
              <li key={order._id}>
                {t("Commande")} {order.order_number}
              </li>
            ))}
          </ul>
          <p>{t("Veuillez les traiter avant de clôturer la caisse.")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary-app"
            onClick={handleClosePendingOrderAlert}
          >
            {t("Fermer")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title> {t("Confirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("Êtes-vous sûr de vouloir clôturer la caisse ?")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary-app"
            onClick={() => setShowConfirmationModal(false)}
          >
            {t("Annuler")}
          </Button>
          <Button
            className="btn btn-primary-app"
            onClick={() => {
              handleOpenJournalCaisseClotureForm();

              setShowConfirmationModal(false);
            }}
          >
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </Modal>
    </CustomModal>
  );
};

export default HomeCaisse;

import React from "react";

export default function ProductTable({
  t,
  productList,
  onEditClick,
  desactiveitem,
  setindex,
  index,
}: any) {
  return (
    <>
      <div className="row">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>{t("refProduit")}</th>
              <th>{t("code a barre")}</th>
              <th>{t("titre")}</th>
              <th>{t("description")}</th>
              <th>{t("caracteristiques")}</th>
              <th>{t("prix")}</th>

              <th className="text-center">{t("Action")}</th>
            </tr>
          </thead>
          <tbody>
            {productList.map((item: any) => (
              <tr key={item._id}>
                <td> {item.refProduit}</td>
                <td> {item.code_a_barreProduit}</td>
                <td> {item?.titre?.fr}</td>
                <td> {item.description?.fr}</td>
                <td>
                  {item.caracteristiques.map((element: any, index: number) => (
                    <p key={index}>
                      {element.libelle}
                      {element.value?.libelle ||element.value }
                    </p>
                  ))}
                </td>

                <td> {item.prix.toFixed(3)}</td>
                <td className="text-center">
                  <ul className="table-controls">
                    <li onClick={() => desactiveitem(item._id)}>
                      <i
                        className="fas fa-trash-alt fa-lg"
                        style={{ color: "#14d8db" }}
                      ></i>
                    </li>
                    <li onClick={() => onEditClick(item)}>
                      <i
                        className="fas fa-pen fa-lg"
                        style={{ color: "#14d8db" }}
                      ></i>
                    </li>
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="row">
          <div className="pagination-no_spacing">
            <ul className="pagination">
              {index > 0 && (
                <li>
                  <a onClick={() => setindex(index - 10)} className="prev">
                    {" "}
                    <i className="fas fa-chevron-left"></i>{" "}
                  </a>{" "}
                </li>
              )}
              <li>
                <a onClick={() => setindex(index + 10)} className="next">
                  <i className="fas fa-chevron-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

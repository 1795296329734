import React, { useEffect, useState } from "react";
import Select from "react-select";
import moment from "moment";
import {
  areArraysEqual,
  calculHt,
  calculTTC_Remise,
  calculTTc,
} from "../../../_App/Helpers/helpers";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  Listmatrice,
  findMatriceById,
} from "../../../_App/Redux/Slices/matrice/matriceSlice";
import Matrice from "../../Products/Products/ProductsForm/Matrice";
import MatriceAchat from "./MatriceAchat";
import { toast } from "react-toastify";
import TaxType from "../../../_App/Types/Entites/TaxType";
export default function AchatFormTable({
  t,
  setShowModalProduct,
  setInputs,
  inputs,
  listProduits,
  societe,
  selecteddepots,
  options,
  listdepots,
  listtva,
  handleSelectChange
}: any) {
  const list_matrice = useAppSelector(Listmatrice);
  const [ShowModalMatrice, setShowModalMatrice] = useState(false);
  const [selectedmatrice, setselectedmatrice] = useState({});
  const [qunatitetotal, setqunatitetotal] = useState(0);
  const [index, setindex] = useState(0);

  const handleInputChange = (
    index: number,
    fieldName: string,
    value: any
  ) => {

    setInputs((prevInputs: any) => {
      const newInputs = [...prevInputs];
     /* const index = newInputs.findIndex(
        (input) => input.product_id === productId
      );*/
    

      if (index !== -1) {
        const oldvalue = newInputs[index][fieldName]
        newInputs[index] = { ...newInputs[index], [fieldName]: value };

        if (fieldName === "prix_ht") {
          const newprixttc = parseFloat(
            calculTTc(value, newInputs[index].tva).toFixed(3)
          );
          newInputs[index] = { ...newInputs[index], ["prix_ttc"]: newprixttc };
          const newNetTTc = calculTTC_Remise(
            newprixttc,
            newInputs[index]?.quantite,
            newInputs[index]?.remise
          );
          newInputs[index] = {
            ...newInputs[index],
            ["total_net_ttc"]: newNetTTc,
          };

          const totalpricht = (value * newInputs[index]?.quantite).toFixed(3);
          newInputs[index] = {
            ...newInputs[index],
            ["total_prix_ht"]: parseFloat(totalpricht),
          };

          const totalprixttc = (
            newprixttc * newInputs[index]?.quantite
          ).toFixed(3);
          newInputs[index] = {
            ...newInputs[index],
            ["total_prix_ttc"]: parseFloat(totalprixttc),
          };
          if(newInputs[index].type==='fils'){
            const parentIndex = inputs.findIndex((item:any) => item.type === 'commentaire' && item.product_id === inputs[index].produit.produitparent);
            

         
              const totalprixinitale = (newInputs[parentIndex].prix_ht * newInputs[parentIndex].quantite); 
              const totalprixinitale_sansini = totalprixinitale - oldvalue ;
              const totalprixinitale_avecini = totalprixinitale_sansini +value ;
              const newprixhtparent = totalprixinitale_avecini/newInputs[parentIndex].quantite ;
              const newprixttc= parseFloat(
                calculTTc(newprixhtparent, newInputs[index].tva).toFixed(3)
              );
            // calcul prixttc
            
           
          newInputs[parentIndex] = { ...newInputs[parentIndex],prix_ht:newprixhtparent.toFixed(3) , prix_ttc: newprixttc.toFixed(3) };
          }
        } 
       
        else if (fieldName === "prix_ttc") {
          const newprixht = parseFloat(
            calculHt(value, newInputs[index].tva).toFixed(3)
          );
          const newNetTTc = calculTTC_Remise(
            value,
            newInputs[index]?.quantite,
            newInputs[index]?.remise
          );
          newInputs[index] = {
            ...newInputs[index],
            ["total_net_ttc"]: newNetTTc,
          };
          newInputs[index] = { ...newInputs[index], ["prix_ht"]: newprixht };

          const totalpricht = (newprixht * newInputs[index]?.quantite).toFixed(
            3
          );
          newInputs[index] = {
            ...newInputs[index],
            ["total_prix_ht"]: parseFloat(totalpricht),
          };
          const totalprixttc = (value * newInputs[index]?.quantite).toFixed(3);
          newInputs[index] = {
            ...newInputs[index],
            ["total_prix_ttc"]: parseFloat(totalprixttc),
          };
          if(newInputs[index].type==='fils'){
            const parentIndex = inputs.findIndex((item:any) => item.type === 'commentaire' && item.product_id === inputs[index].produit.produitparent);

            const totalprixinitale = (newInputs[parentIndex].prix_ttc * newInputs[parentIndex].quantite); 
              const totalprixinitale_sansini = totalprixinitale - oldvalue ;
              const totalprixinitale_avecini = totalprixinitale_sansini +value ;
              const newprixttcparent = totalprixinitale_avecini/newInputs[parentIndex].quantite ;
              const newprixht= parseFloat(
                calculHt(newprixttcparent, newInputs[index].tva).toFixed(3)
              );
              newInputs[parentIndex] = { ...newInputs[parentIndex],prix_ht:newprixht.toFixed(3) , prix_ttc: newprixttcparent.toFixed(3) };

          }
        } else if (fieldName === "quantite") {
          const totalprixttc = (newInputs[index]?.prix_ttc * value).toFixed(3);
          newInputs[index] = {
            ...newInputs[index],
            ["total_prix_ttc"]: parseFloat(totalprixttc),
          };
          const newNetTTc = calculTTC_Remise(
            newInputs[index]?.prix_ttc,
            value,
            newInputs[index]?.remise
          );
          newInputs[index] = {
            ...newInputs[index],
            ["total_net_ttc"]: newNetTTc,
          };
          const totalpricht = (newInputs[index]?.prix_ht * value).toFixed(3);
          newInputs[index] = {
            ...newInputs[index],
            ["total_prix_ht"]: parseFloat(totalpricht),
          };

          if(newInputs[index].type === 'fils'){
           
            const inputligne = inputs[index]
            const parentIndex = inputs.findIndex((item:any) => item.type === 'commentaire' && item.product_id === inputligne.produit.produitparent);
            // Check if the item was found
            if (parentIndex !== -1) {
              
              const parentValue = inputs[parentIndex];
            
              // update detail parent
              const item = {dimension : inputligne.details[0].dimension , valeur: value }
              const ledetail = parentValue.details;
          

              // Filter out elements with equal dimensions
              const filteredDetails = ledetail.filter(
                (element: any) =>
                  !areArraysEqual(element.dimension, item.dimension)
              );
              const total = [...filteredDetails, item].reduce((accumulator: any, item: any) => {
                  return accumulator + (item.valeur || 0);
                }, 0)
              
              const newprixht = (newInputs[parentIndex].prix_ht * newInputs[parentIndex].quantite )/total
              const newprixttc = (newInputs[parentIndex].prix_ttc * newInputs[parentIndex].quantite )/total

                newInputs[parentIndex] = { ...newInputs[parentIndex],  details: [...filteredDetails, item],
                  quantite: total  ,prix_ht:newprixht.toFixed(3) ,prix_ttc:newprixttc.toFixed(3)}; 
           
            }
          }
        } else if (fieldName === "remise") {
          // clacul nettcc
          const newNetTTc = calculTTC_Remise(
            newInputs[index]?.prix_ttc,
            newInputs[index]?.quantite,
            value
          );
          newInputs[index] = {
            ...newInputs[index],
            ["total_net_ttc"]: newNetTTc,
          };
        } else if (fieldName === "depots_id") {
          newInputs[index] = {
            ...newInputs[index],
            ["depots_id"]: value,
          };
        }
      }
    

      return newInputs;
    });
  };

  const getparent =(index:number)=>{
    const inputligne = inputs[index]
    const parentIndex = inputs.findIndex((item:any) => item.type === 'commentaire' && item.product_id === inputligne.produit.produitparent);
    // Check if the item was found
    if (parentIndex !== -1) {
      const parentValue = inputs[parentIndex];

      setInputs((prevInputs: any) => {
        const newInputs = [...prevInputs];
        newInputs[parentIndex] = { ...newInputs[parentIndex], show: !parentValue.show}; 
        return newInputs;
      });
    } else {
      console.log('Item not found');
    }
   
  }

  const removeitem = (index: number) => {

    setInputs((prevInputs: any) => {
      const newInputs = [...prevInputs];

      if(newInputs[index].type === 'fils'){
           
        const inputligne = inputs[index]
        const parentIndex = inputs.findIndex((item:any) => item.type === 'commentaire' && item.product_id === inputligne.produit.produitparent);
        // Check if the item was found
        if (parentIndex !== -1) {
       
          const parentValue = inputs[parentIndex];
        
          // update detail parent
          const item = {dimension : inputligne.details[0].dimension , valeur: 0 }
          const ledetail = parentValue.details;
      

          // Filter out elements with equal dimensions
          const filteredDetails = ledetail.filter(
            (element: any) =>
              !areArraysEqual(element.dimension, inputligne.details[0].dimension )
          );
          const total = [...filteredDetails, item].reduce((accumulator: any, item: any) => {
              return accumulator + (item.valeur || 0);
            }, 0)
        
            newInputs[parentIndex] = { ...newInputs[parentIndex],  details: filteredDetails,
              quantite: total}; 
       ;
        }
      }
             newInputs.splice(index, 1);
      
     
      return newInputs;
    });
 
   
  };

  const getmatrice = (matriceid: any , index: number) => {
    setqunatitetotal(inputs[index].quantite)
    const matrice = list_matrice.find((item) => item._id === matriceid);
    setindex(index)

    if (matrice) {
      setShowModalMatrice(true);
      setselectedmatrice(matrice);
    } else {
      toast.warning("le matrice n'existe pas");
    }
  };

  return (
    <div className="table-responsive">
      <table className="table table-bordered mb-5">
        <thead>
          <tr>
            <th></th>
            <th>{t('Produit')}</th>
            <th>{t('Depots')}</th>
            <th>{t('Quantite')}</th>
            <th>{t('Prix Achat HT')}</th>
            <th>{t('Prix Achat TTC')}</th>
            <th>{t('Remise')}</th>
            <th>{t('TVA')}</th>
            <th>{t('Total HT')}</th>
            <th>{t('Total TTC')}</th>
            <th>{t('Total Net TTC')}</th>
          </tr>
        </thead>

        {inputs.length !== 0 ? (
          <tbody>
            {inputs?.map((item: any, index: number) => (
               item.show  ?
              <tr key={item._id}>
                <td onClick={() => removeitem(index)}>
                  {" "}
                  <i className="fas fa-trash-alt color-red" />
                </td>

                <td>
                  {" "}
                  {item.produit.type === "parent"  &&  item.produit.matrice !== null? (
                    <span onClick={() => getmatrice(item.produit.matrice ,index)}>
                      <i className="fas fa-th-large"></i>
                    </span>
                  ) : item.type==='fils' ? (
                    <span onClick={()=>getparent(index)}><i className="fab fa-product-hunt"></i></span>
                  ):null}
                  {item.produit?.titre?.fr}
                </td>
                <td>
                  <select
                    className=""
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "depots_id",
                        e.target.value
                      )
                    }
                    value={
                      inputs[index]?.depots_id
                    }
                  >
                    {listdepots.map((item: any, index: number) => (
                      <option key={index} value={item._id}>
                        {item.libelle}
                      </option>
                    ))}
                  </select>
                </td>

                <td>
                  <input
                    type="number"
                    value={ inputs[index]?.quantite || ""
                    }
                    disabled={item.type === 'commentaire'}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "quantite",
                        +e.target.value
                      )
                    }
                    className="input-gray-border numberinput"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    disabled={item.type === 'commentaire'}

                    value={ inputs[index]?.prix_ht.toFixed(3) || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "prix_ht",
                        +e.target.value
                      )
                    }
                    className="input-gray-border numberinput"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    disabled={item.type === 'commentaire'}

                    value={ inputs[index]?.prix_ttc.toFixed(3) || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "prix_ttc",
                        +e.target.value
                      )
                    }
                    className="input-gray-border numberinput"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    disabled={item.type === 'commentaire'}

                    value={ inputs[index]?.remise || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "remise",
                        +e.target.value
                      )
                    }
                    className="input-gray-border numberinput"
                  />
                </td>
                <td>{item.tva}</td>
                <td>
                  { inputs[index]?.total_prix_ht.toFixed(3) || 0}
                </td>
                <td>
                  { inputs[index]?.total_prix_ttc.toFixed(3) || 0}
                </td>
                <td>
                  { inputs[index]?.total_net_ttc.toFixed(3) || 0}
                </td>
              </tr>
              :null
            ))}
          </tbody>
        ) : null}

        <tbody>
          <tr>
            <td onClick={() => setShowModalProduct(true)}>
              {" "}
              <span className="c-primary float-right my">
              <i className="fas fa-plus-circle fa-lg"></i>
              </span>
            </td>
            <td colSpan={2}>
              <Select
                options={options}
                isSearchable={true}
                onChange={handleSelectChange}
              />{" "}
            </td>
          </tr>
        </tbody>
      </table>
      <MatriceAchat
        t={t}
        ShowModal={ShowModalMatrice}
        setShowModal={setShowModalMatrice}
        matrice={selectedmatrice}
        quantite={qunatitetotal}
        setInputs={setInputs}
        index={index}
        inputs={inputs}
        listProduits={listProduits}
        taxes={listtva}
        selecteddepots={selecteddepots}
        societe={societe}
      />
    </div>
  );
}

import React from "react";
import { useAppSelector } from "../../_App/Redux/hooks";
import { Listrapport } from "../../_App/Redux/Slices/rapport/rapportSlice";

export default function RapportVenteAchat({t}:any) {
  const listrapport = useAppSelector(Listrapport);
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th >{t('Article')}</th>
            <th>{t('Famille')}</th>
            <th> {t('Quantité achetée')}</th>
            <th >{t('Prix Achat')} </th>
            <th>{t('PMP')}</th>
            <th>{t('Prix Achat total')}</th>
            <th>{t('Quantité Vendue')}</th>
            <th>{t('Prix Vente Unitaire')}</th>
            <th>{t('Prix Vente total')}</th>
            <th>{t('Quantité restante')}</th>
            <th> {t('Achat/ vente')}</th>
          </tr>
        </thead>
        <tbody>
          {listrapport &&
            listrapport.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item?.article?.titre?.fr}</td>
                <td>{item?.article?.souscategorie?.libelle?.fr}</td>
                <td>{item?.qteAchat}</td>
                <td>{item?.prixAchat}</td>
                <td>{item?.article?.pmp?.toFixed(2)}</td>
                <td>{(item?.AchatTT || 0).toFixed(2)}</td>
                <td>{item?.qteVente}</td>
                <td>{(item?.prixVente / item?.qteVente || 0).toFixed(3)}</td>
                <td>{item?.prixVente}</td>
                <td>{item.qteAchat - item.qteVente}</td>
                <td>
                  {item?.prixVente
                    ? ((item?.AchatTT || 0) / item.prixVente).toFixed(3)
                    : "-"}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

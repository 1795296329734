import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import RaccourciProductList from "../../Components/Caisses/ProductsRaccourcis/RaccourciProductList";
import { useNavigate } from "react-router-dom";

import {
  ListCartes,
  findCartesApi,
} from "../../_App/Redux/Slices/cartes/carteSlice";
import CaisseCartesList from "../../Components/Caisses/Cartes/CaisseCartesList";
import {
  ListCategories,
  ListCategoriesByCarte,
  findCategoriesApi,
} from "../../_App/Redux/Slices/categories/categorieSlice";
import {
  ListSousCategories,
  ListSousCategoriesByCategorie,
  findSousCategoriesApi,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import {
  Display,
  Filter,
  changeDisplay,
  layout,
} from "../../_App/Redux/Slices/Caisse/CaisseSlice";

import {
  ListProduits,
  findProductByOrganisationApi,
} from "../../_App/Redux/Slices/produits/produitSlice";
import CaisseCategoriesList from "../../Components/Caisses/Categories/CaisseCategoriesList";
import CaisseSousCategoriesList from "../../Components/Caisses/SousCategories/CaisseSousCategoriesList";
import CaisseProductsList from "../../Components/Caisses/Products/CaisseProductsList";
import CaisseProductsDetailsList from "../../Components/Caisses/Articles/CaisseArticlesList";
import {
  ListProductDetails,
  listProductsDetailByProduct,
  findProductsDetailApi,
} from "../../_App/Redux/Slices/product_detail/product_detailSlice";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  ListRaccourci,
  findProductRaccourciByOrganisationApi,
} from "../../_App/Redux/Slices/product_raccourci/ProductRaccourciSlice";
import NavbarCaisse from "../../_Layouts/Navbar/NavbarCaisse";
import {
  SelectedOrganisationId,
  selectedAutorisations,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { ActiveMenu } from "../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import {
  ListArticleBySousCategorie,
  ListArticles,
  findArticleApi,
} from "../../_App/Redux/Slices/article/articleSlice";
import OrdersList from "../../Components/Caisses/Orders/OrdersList";
import CaisseArticlesItem from "../../Components/Caisses/Articles/CaisseArticlesItem";
import CaisseArticlesList from "../../Components/Caisses/Articles/CaisseArticlesList";
import OrdersListAdmin from "../../Components/Caisses/Orders/OrdersListAdmin";

// Dispatching the action

const VentesPage = () => {
  const dispatch = useAppDispatch();
  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const cartes = useAppSelector(ListCartes);
  const categories = useAppSelector(ListCategories);
  const categoriesByCarte = useAppSelector(ListCategoriesByCarte);

  const sousCategories = useAppSelector(ListSousCategories);
  const sousCategoriesByCategorie = useAppSelector(
    ListSousCategoriesByCategorie
  );

  const articles = useAppSelector(ListArticleBySousCategorie);

  const filter = useAppSelector(Filter);
  const display = useAppSelector(Display);
  const Layout = useAppSelector(layout);

  const handleDisplayChange = async ({
    displayColLeft,
    colRight,
    containerRight,
  }: any) => {
    dispatch(
      changeDisplay({
        colLeft: display.colLeft,
        containerLeft: display.containerLeft,
        displayColLeft: displayColLeft,
        colRight: colRight,
        containerRight: containerRight,
      })
    );
  };
  useEffect(() => {
    if (currentOrganisation) {
      dispatch(findProductRaccourciByOrganisationApi(currentOrganisation));
      dispatch(findCartesApi(currentOrganisation));
      dispatch(findCategoriesApi(currentOrganisation));
      // dispatch(findSousCategoriesApi(currentOrganisation));
      // dispatch(findArticleApi(currentOrganisation));
    }
  }, [dispatch]);

  const autorisations = useAppSelector(selectedAutorisations);
  const canViewOrders = autorisations?.CAN_VIEW_ORDERS;

  const navigate = useNavigate();

  useEffect(() => {
    if (!canViewOrders) {
      navigate("/unauthorized");
    }
  }, [canViewOrders, navigate]);

  return (
    <>
      <NavbarCaisse Title={"Commandes"} />

      {/* <DndProvider backend={HTML5Backend}> */}
      <div
        className="caisse-data-content"
        style={{ marginLeft: Layout.marginLeft }}
      >
        <div className="row">
          <div className={display.containerRight}>
            <OrdersListAdmin />
          </div>
        </div>
      </div>
      {/* </DndProvider> */}
    </>
  );
};

export default VentesPage;

import React from "react";

export default function NonVenduList({ t, listArticle }: any) {
  console.log('listArticle',listArticle)
  return (
    <div>
      <div className="row layout-spacing mt-5">
        <div className="col-lg-12   bg-white  ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <th>{t("Article")}</th>
                <th>{t("Code Article")}</th>

                <th>{t("Famille")}</th>
                <th>{t("Prix TTC")}</th>
                <th>{t("Prix HT ")}</th>
                <th>{t("tva ")}</th>
                <th>{t("Prix dernier achat ")}</th>
                <th>{t('total Qte deriner vente')}</th>
                <th>{t('total prix deriner vente')}</th>
                <th>{t("PMP")}</th>
              </thead>
              <tbody>
                {listArticle &&
                  listArticle.length !== 0 &&
                  listArticle.map((item: any, index: number) => (
                    <tr key={index}>
                      <td>{item?.titre?.fr}</td>
                      <td>{item?.code_article}</td>
                      <td>{item?.souscategorie?.libelle?.fr}</td>
                      <td>{item?.prix_vente_ttc.toFixed(3)}</td>
                      <td>{item?.prix_vente_ht.toFixed(3)}</td>
                      <td>{item?.tva}</td>
                      <td>{item?.pda}</td>
                      <td>{item.totalQte.toFixed(3)||0}</td>
                      <td>{item.totalprix.toFixed(3)||0}</td>
                      <td>{item?.pmp}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react'
import FiltreRapport from '../../Components/Rapport/FiltreRapport';
import { useAppDispatch } from '../../_App/Redux/hooks';
import { findRapportAchatVenteApi } from '../../_App/Redux/Slices/rapport/rapportSlice';
import RapportVenteAchat from '../../Components/Rapport/RapportVenteAchat';
import { useTranslation } from 'react-i18next';

export default function Rapport() {
  const { t } = useTranslation();
    const dispatch = useAppDispatch()
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);

    // Add one day to today
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const [filtre,setFiltre]=useState({
        dateDebut:formatDate(lastMonth),
        dateFin:formatDate(tomorrow),
        selectedSousCategorie:null,
        listfournisseur:null,
        article:null
    })
    const handleSearch =()=>{
      dispatch(findRapportAchatVenteApi(filtre)) 
    }
  return (
    <div>
      
      <FiltreRapport  t={t} filtre={filtre} setFiltre={setFiltre} handleSearch={handleSearch}/>
      <RapportVenteAchat t={t}/>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import VerifAdmin from "../../Components/VerifUserCode/VerifAdmin";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  annulerOrderApi,
  getOrderByIdApi,
  order,
} from "../../_App/Redux/Slices/Orders/OrderSlice";
import Zoom from "react-medium-image-zoom";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";

export default function AnnulerOrder() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [Admin, setAdmin] = useState();
  const [confirm, setConfirmer] = useState(false);
  const [showModalAdmin, setshowModalAdmin] = useState(false);
  const { orderid } = useParams();
    const [isBlocked, setIsBlocked] = useState(false);
  
  const commande = useAppSelector(order);
  useEffect(() => {
    setAdmin(undefined);
    setshowModalAdmin(true);
  }, []);
  useEffect(() => {
    if (orderid) {
      dispatch(getOrderByIdApi(orderid));
    }
  }, [orderid]);
    useEffect(() => {
      if (confirm && commande) {
        if (commande.status === "Annulée") {
          setIsBlocked(true); // Block the page and show a message
          Swal.fire({
            title: `Commande Num   ${commande.order_number}` ,
            text: `Status de commande est ${commande.status}`,
          });
        }
      }
    }, [commande, confirm]);
  const annulerOrder = async () => {

    const obj = { orderId: orderid };
    const resp = await dispatch(annulerOrderApi(obj));
    if (resp && resp.payload.success) {
        alert(resp.payload.message)
      ToastSuccess(resp.payload.message);
    }
  };
  return (
    <div>
            <ToastContainer />
      
      { confirm&&isBlocked ? (
        <div></div>
      ) : (
        <div className="container mt-4">
        <h3>
          {t("Demande de ")}
          {commande?.user_id.nom + " " + commande?.user_id.prenom}{" "}
        </h3>

        <h3>
          {t("Client")}{" "}
          {commande?.client_id?.defaultClient
            ? commande?.client_id.nom
            : commande?.client_id.nom + " " + commande?.client_id.prenom}
        </h3>
        {commande?.items?.length !== 0 &&
          commande?.items?.map((item: any, index: any) => (
            <div
              className="row mb-4 p-3 rounded shadow-sm bg-light align-items-center"
              key={index}
            >
              {/* Image Section */}
              <div className="col-3 d-flex justify-content-center align-items-center">
                <Zoom>
                  <img
                    alt={item?.article?.titre?.fr || "Item Image"}
                    src={
                      item?.article?.image && item?.article?.image[0]
                        ? `${process.env.REACT_APP_API_PUBLIC_URL}article/${item?.article?.image[0]}`
                        : "/assets/assets/img/no-image.jpg"
                    }
                    className="img-fluid rounded-circle shadow"
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      objectFit: "cover",
                    }}
                  />
                </Zoom>
              </div>

              {/* Title Section */}
              <div className="col-8 d-flex flex-column justify-content-center">
                <p
                  className="user-name text-truncate"
                  style={{ fontWeight: "600", fontSize: "1.2rem" }}
                >
                  {item?.article?.titre?.fr || "No title"}
                </p>
              </div>

              {/* Price Section */}
              <div className="col-3 d-flex justify-content-center align-items-center">
                <p className="mb-0">
                  {" "}
                  prix initiale {item?.article?.prix_vente_ttc.toFixed(3) || "N/A"}
                </p>
              </div>

              {/* Discount Section */}
              <div className="col-3 d-flex justify-content-center align-items-center">
                <p className="mb-0">
                  Remise: {item?.remiseprop || item.remise}%
                </p>
              </div>

              {/* Net Price Section */}
              <div className="col-4 d-flex justify-content-center align-items-center">
                <p className="mb-0"> Net à payer: {item?.prixNet.toFixed(3)}</p>
              </div>
            </div>
          ))}
        {/* Action Buttons */}
        <div className="d-flex justify-content-end mt-3">
          <button className="btn btn-success mx-2" onClick={annulerOrder}>{t("Confirmer")}</button>
        </div>
      </div>
      )}
      
      <VerifAdmin
        ShowModal={showModalAdmin}
        setShowModal={setshowModalAdmin}
        societe={null}
        user={Admin}
        setuser={setAdmin}
        setConfirmer={setConfirmer}
      />
    </div>
  );
}

import React from "react";

export default function DemandeRemiseList({ t, list }: any) {
  return (
    <div>
      <div className="row widget-content searchable-container list full-width">
        <div className="searchable-items list full-width">
          <div className="items items-header-section item-content full-width">
            <div className="user-email text-center  col-1">
              <h4>{t("Article")}</h4>
            </div>
            <div className="user-email text-center  col-2">
              <h4>{t("Client")}</h4>
            </div>
            <div className="user-email text-center col-2">
              <h4>{t("U.demande")}</h4>
            </div>

            <div className="user-email text-center  col-1">
              <h4>{t("remise proposition")}</h4>
            </div>

            <div className="user-email text-center col-2">
              <h4>{t("Note")}</h4>
            </div>
            <div className="user-email text-center col-1">
              <h4>{t("Etat")}</h4>
            </div>
            <div className="user-email text-center col-2">
              <h4>{t("U.confirm")}</h4>
            </div>
           
            <div className="user-email text-center col-1">
              <h4>{t("Remise validé")}</h4>
            </div>
          </div>
        </div>
      </div>
      <div>
      {list &&
        list.map((item: any, index: number) => (
          <div
            key={index}
            className="row widget-content searchable-container list full-width"
          >
            <div className="searchable-items list full-width">
              <div className="items items-header-section item-content full-width">
                <div className="user-email text-center  col-1">
                  <h4>{item?.article?.titre?.fr}</h4>
                  {item?.article?.caracteristiques?.length ? (
                    <p>
                      {item?.article?.caracteristiques.map(
                        (caracteristique: any) =>
                          caracteristique?.value?.libelle
                            ? caracteristique?.value?.libelle + " "
                            : caracteristique?.value + " "
                      )}
                    </p>
                  ) : null}
                </div>
                <div className="user-email text-center  col-2">
                  <h4>
                    {item.Client?.nom + " " + (item.client?.prenom ||'')}
                  </h4>
                </div>
                <div className="user-email text-center  col-2">
                  <h4>
                    {item.user_demande?.nom + " " + item.user_demande?.prenom}
                  </h4>
                </div>
                <div className="user-email text-center  col-1">
                  <h4>
                    {item.remisepropsition
                      ? item.remisepropsition
                      : item.remise}
                  </h4>
                </div>

                <div className="user-email text-center col-2">
                  <h4>{item?.note}</h4>
                </div>
                <div className="user-email text-center col-1">
                  <h4>{item?.valide ===undefined?"-" :item?.valide? "Valide" :'Non Valide'}</h4>
                </div>


                <div className="user-email text-center  col-2">
                  <h4>
                    {item.user_confirm?.nom + " " + item.user_confirm?.prenom}
                  </h4>
                </div>
                <div className="user-email text-center  col-1">
                  <h4>
                    {item?.valide&&item.remise}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
     
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import DemandeRemiseList from '../../Components/DemandeRemise/DemandeRemiseList'
import { useAppDispatch, useAppSelector } from '../../_App/Redux/hooks'
import { findDemandeRemiseApi, ListDemandeRemise } from '../../_App/Redux/Slices/demandeRemise/demandeRemiseSlice'
import { SelectedOrganisationId, societeId } from '../../_App/Redux/Slices/Auth/authSlice'
import { useTranslation } from 'react-i18next'
import FiltreListDemandeRemise from '../../Components/DemandeRemise/FiltreListDemandeRemise'
import { allOrganisationsApi, ListOrganisations } from '../../_App/Redux/Slices/organisations/organisationSlice'
import { findUserByOrganisationApi, ListUsers } from '../../_App/Redux/Slices/users/userSlice'

export default function DemandeRemiseTransction() {
    const { t } = useTranslation();
    const [filtre,setFiltre]=useState({
      organistaion:null as unknown as string,
      societe:null as unknown as string,
      valide:'tout',
      demandeur:'tt',
    })
  const dispatch = useAppDispatch()
  const listdemanderemise = useAppSelector(ListDemandeRemise)
  const listOragnisation=useAppSelector(ListOrganisations)
  const selectedOrganisation = useAppSelector(SelectedOrganisationId)
  const societe_id=useAppSelector(societeId)
  const listUsers = useAppSelector(ListUsers )


  useEffect(()=>{
    if(societe_id&&selectedOrganisation){
      setFiltre({...filtre,societe:societe_id,organistaion:selectedOrganisation})
      dispatch(findDemandeRemiseApi({
        organistaion:selectedOrganisation,
        societe:societe_id,
        valide:'tout',
        demandeur:'tt',
      }))
      dispatch(allOrganisationsApi(societe_id))
    }
   

  },[societe_id,selectedOrganisation])

  useEffect(()=>{
   if(filtre.organistaion !== null) dispatch(findUserByOrganisationApi(filtre.organistaion))

  },[filtre.organistaion])

  const handleFind=()=>{
    dispatch(findDemandeRemiseApi(filtre))

  }
  return (
    <div>
      <FiltreListDemandeRemise 
        t={t}
        listOragnisation={listOragnisation}
        filtre={filtre}
        setFlitre={setFiltre}
        listUser={listUsers}
        handleFind={handleFind}/>
      <DemandeRemiseList list={listdemanderemise} t={t} />
    </div>
  )
}

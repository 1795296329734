import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useAppDispatch } from "../_App/Redux/hooks";
import {
  getOrderByIdApi,
  updateRetourEchange,
} from "../_App/Redux/Slices/Orders/OrderSlice";
import { useParams } from "react-router-dom";
import { findAdminBySocieteApi } from "../_App/Redux/Slices/users/userSlice";
import { UserDataType } from "../_App/Types/Forms/UserDataType";
import Swal from "sweetalert2";

const RetourEchangeConfirmationPage = () => {
  let { societe, id } = useParams();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [inputCode, setInputCode] = useState("");
  const [error, setError] = useState("");
  const [order, setOrder] = useState<any>();
  const [admin, setAdmin] = useState<UserDataType | undefined>(undefined);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchOrder = async () => {
      if (id) {
        const response = await dispatch(getOrderByIdApi(id));
        if (response.payload) setOrder(response.payload);
      }
      if (societe) {
        const res = await dispatch(findAdminBySocieteApi(societe));
        if (res.payload.success) setAdmin(res.payload.data);
      }
    };
    fetchOrder();
  }, [dispatch, id]);

  const handleCodeSubmit = () => {
    if (inputCode === admin?.my_code_pin) {
      setIsAuthorized(true);
      setError("");
    } else {
      setError("Code incorrect. Veuillez réessayer.");
    }
  };
  const handleUpdate = async (confirmed: boolean) => {
    try {
      const res = await dispatch(
        updateRetourEchange({
          id: order.retour_echanges._id,
          data: { confirmed },
        })
      );

      if (res.payload.success) {
        Swal.fire({
          title: "Mise à jour réussie !",
          icon: "success",
        });
        
      } else {
        throw new Error("La mise à jour a échoué.");
      }
    } catch (error) {
      Swal.fire({
        title: "Erreur",
        text: "Une erreur inattendue s'est produite.",
        icon: "error",
      });
    }
  };

  return (
    <div className="container mt-5">
      {!isAuthorized ? (
        <Card>
          <Card.Body>
            <Card.Title>Accès Restreint</Card.Title>
            <Card.Text>
              Cette page est protégée. Veuillez entrer le code pour accéder à la
              confirmation du retour échange.
            </Card.Text>
            <Form.Group className="mb-3" controlId="formCode">
              <Form.Label>Code d'accès</Form.Label>
              <Form.Control
                type="password"
                placeholder="Entrez le code"
                value={inputCode}
                onChange={(e) => setInputCode(e.target.value)}
              />
            </Form.Group>
            {error && <p className="text-danger">{error}</p>}
            <Button variant="primary" onClick={handleCodeSubmit}>
              Valider
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <Card>
          <Card.Body>
            <Card.Title>Confirmation du Retour Échange</Card.Title>
            <Card.Text>
              Votre Retour Échange pour le ticket ID{" "}
              <strong>{order?.id}</strong> a été créé avec succès.
              <br />
              Veuillez vérifier les détails ci-dessous :
              <ul>
                <li onClick={() => console.log(order)}>
                  Articles à retourner :{" "}
                  <strong>{order?.itemsToReturn?.length} articles</strong>
                </li>
                <li>
                  Articles à échanger :{" "}
                  <strong>{order?.itemsToReturn?.length} articles</strong>
                </li>
                <li>
                  ID de commande : <strong>{order?.order_number}</strong>
                </li>
                <li>
                  Client : <strong>{order?.customer_name}</strong>
                </li>
                <li>
                  Date de création : <strong>{order?.creation_date}</strong>
                </li>
              </ul>
              Êtes-vous sûr de vouloir confirmer le processus de retour échange
              ?
            </Card.Text>
            <div className="d-flex justify-content-around">
              <Button variant="success" onClick={() => handleUpdate(true)}>
                Oui
              </Button>
              <Button variant="danger" onClick={() => handleUpdate(false)}>
                Non
              </Button>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default RetourEchangeConfirmationPage;

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import TicketDeCaisse from "../../../Components/Caisses/TicketDeCaisse/TicketDeCaisseModal";
import {
  DefaultClient,
  Panier,
  Reduction,
  addClient,
  confirmOrder,
  findDefaultClientsBySociete,
  resetPanier,
  resetReduction,
  setOperationType,
} from "../../../_App/Redux/Slices/Caisse/PanierSlice";
import { selectedTable } from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import {
  ListOnlineOrders,
  ListOrders,
  findOrdersByOrganisation,
  getOrderByIdApi,
  order,
  saveOrderApi,
  selectedOrder,
  updateOrderApi,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import {
  SelectedOrganisationId,
  selectJournalCaisse,
  selectUser,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";

import {
  UnpaidTicket,
  findTicketsByOrderApi,
  saveTicketApi,
} from "../../../_App/Redux/Slices/tickets/TicketSlice";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { DepotDefautOrganisation } from "../../../_App/Redux/Slices/depot/depotSlice";
import { findVendeurs } from "../../../_App/Redux/Slices/users/vendeurSlice";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import {
  createClientsApi,
  findClientById,
  findClientBySocieteApi,
} from "../../../_App/Redux/Slices/client/clientSlice";

import ClientType from "../../../_App/Types/Entites/ClientType";

import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ClientSchema } from "../../../_App/Schemas/ClientSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver, useForm } from "react-hook-form";
import {
  findSoucheCommande,
  findSoucheCommandeOnline,
  generateOrderNumber,
} from "../../../_App/Helpers/helpers";
import { updateArticleStockApi } from "../../../_App/Redux/Slices/article/articleSlice";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import OnlineOrderInvoice from "../../../Components/Caisses/Orders/OnlineOrderInvoice";
import { useNavigate } from "react-router-dom";

interface PaymentRightBarProps {
  visible: boolean;
  setVisible: any;
  ArticlesToPay: any;
  miniOrder: any;
}

const OnlineOrderConfirmation: React.FC<PaymentRightBarProps> = ({
  visible,
  setVisible,
  miniOrder,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cart = useSelector(Panier);
  const reduction = useAppSelector(Reduction);
  let tempReduction = reduction;

  const retrievedOrder = useAppSelector(order);
  const currentOrder = useAppSelector(selectedOrder);
  const openedOrder = retrievedOrder ? retrievedOrder : currentOrder;
  const selectedtable = useAppSelector(selectedTable);
  const orders = useAppSelector(ListOnlineOrders);
  const unpaidTicket = useAppSelector(UnpaidTicket);
  const journal = useAppSelector(selectJournalCaisse);
  const defaultClient = useAppSelector(DefaultClient);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const user = useAppSelector(selectUser);

  const societe_id = useAppSelector(societeId);

  const [tempCash, setTempCash] = useState<number | 0>(0);
  const [tempCheque, setTempCheque] = useState<number | 0>(0);
  const [showModalTicket, setShowModalTicket] = useState(false);

  const [ticketId, setTicketId] = useState<string | null>(null); // Assuming the ticket ID is a string, adjust if necessary
  const currentDepot = useAppSelector(DepotDefautOrganisation);
  const parametrage = useAppSelector(parametrageCaisse);
  const btnClass = parametrage && parametrage.barcodeOnly ? " big-btn" : "";
  const { t } = useTranslation();
  const openedOrderId = openedOrder?._id;

  const total = openedOrder
    ? miniOrder
      ? miniOrder.total
      : unpaidTicket
      ? unpaidTicket.total
      : openedOrder.total
    : cart.operationType === "Retour"
    ? cart.totalNet
    : cart.totalNet;

  const [currentClient, setCurrentClient] = useState<ClientType>();

  useEffect(() => {
    const getClient = async () => {
      if (cart.client_id) {
        const loyalClient = await dispatch(
          findClientById(cart.client_id.toString())
        );
        if (loyalClient && loyalClient.payload)
          setCurrentClient(loyalClient.payload);
      }
    };
    getClient();
  }, [cart.client_id]);

  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");
  const [mobile, setMobile] = useState("");
  const resetCartToDefault = async () => {
    if (defaultClient) {
      dispatch(resetPanier());
      dispatch(resetReduction());
      dispatch(addClient(defaultClient._id));
    }
  };

  const handleConfirmOrder = async (data: any, client: string) => {
    let order = { ...data };
    delete order.calculatedQuantity;
    delete order.orderConfirmed;
    let response: any;
    if (currentOrganisation) {
      order.organisation_id = currentOrganisation;
    }
    order.items = cart.items;
    order.date = new Date();
    order.table_id = selectedtable ? selectedtable._id : null;
    order.total = cart.total;
    order.totalNet = cart.totalNet;
    order.order_number = await findSoucheCommandeOnline(societe_id, dispatch);
    order.status = "En cours";
    order.client_id = client;
    order.clientRemise = cart.clientRemise;
    order.societe_id = societe_id;
    order.depots_id = currentDepot._id;
    order.type = "online";

    if (journal) order.journal_id = journal?._id;
    if (user) order.vendeur = user?._id;
    if (user) order.user_id = user?._id;
    try {
      const response = await dispatch(saveOrderApi(order));

      if (response.payload.success) {
        setSavedOrder(response.payload.data._id);
        const obj = {
          ticket: {
            main_order: response.payload.data._id,
            ticket_order: response.payload.data,
            type: "Total",
            process: "Online",
          },
        };
        const responseTicket = await dispatch(saveTicketApi(obj));

        console.log("responseTicket", responseTicket);
        if (responseTicket.payload.success)
          setTicketId(responseTicket.payload.data?.ticket?._id);
        setFacture(true);
      }
      order.items.forEach((value: any) => {
        dispatch(
          updateArticleStockApi({
            id: value.article._id,
            depotId: currentDepot._id,
            soldQuantity: value.quantity,
            type: value?.type,
            defaut_id: value?.defaut_id,
          })
        );
      });
      if (response.payload.success) {
        dispatch(confirmOrder());
        resetCartToDefault();
        setNom("");
        setAddress("");
        setMobile("");
        setEmail("");
        setNote("");
        navigate("/commandes");
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      // Handle error appropriately
      console.error(error);
    } finally {
    }
  };

  const [savedOrder, setSavedOrder] = useState(undefined);
  const handleRetour = async () => {
    if (retrievedOrder) {
      try {
        retrievedOrder.items.forEach((value: any) => {
          console.log("itemsvalue", value);
          dispatch(
            updateArticleStockApi({
              id: value.article._id,
              depotId: currentDepot._id,
              soldQuantity: -value.qteretour || -value.quantity,
              type: value?.type,
              defaut_id: value?.defaut_id,
            })
          );
        });
        const response = await dispatch(
          updateOrderApi({
            orderId: retrievedOrder._id,
            status: "Retour",
            paidProducts: [],
            vendeur: retrievedOrder.vendeur,
          })
        );

        if (response.payload.success)
          Swal.fire("success", "Commande annulée.", "success");
        navigate("/commandes");
      } catch (error) {
        console.error("Error during refund process:", error);
      }
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Swal.fire({
      title: "Voulez-vous confirmer ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (retrievedOrder) {
          if (retrievedOrder.status === "En cours") {
            const response = await dispatch(
              updateOrderApi({
                orderId: retrievedOrder._id,
                status: "En livraison",
                paidProducts: [],
                vendeur: retrievedOrder.vendeur,
              })
            );

            if (response.payload.success) {
              Swal.fire("success", "Commande en livraison.", "success");
              navigate("/commandes");
            }
          } else if (
            retrievedOrder &&
            retrievedOrder.status === "En livraison"
          ) {
            dispatch(setOperationType({ type: "Pay", refunded: [] }));
          }
        } else if (cart.operationType === "Echange" && cart.refundedOrder) {
          cart.items.forEach((value: any) => {
            dispatch(
              updateArticleStockApi({
                id: value.article._id,
                depotId: currentDepot._id,
                soldQuantity: value.quantity,
                type: value?.type,
                organisation: currentOrganisation,
                defaut_id: value?.defaut_id,
              })
            );
          });
          let order: any = { ...cart };
          delete order.calculatedQuantity;
          delete order.orderConfirmed;

          if (currentOrganisation) {
            order.organisation_id = currentOrganisation;
          }

          order.items = cart.items;
          order.date = new Date();
          order.table_id = selectedtable ? selectedtable._id : null;
          order.total = cart.total.toFixed(3);
          order.totalNet = cart.totalNet.toFixed(3);
          order.order_number = await findSoucheCommande(societe_id, dispatch);
          order.status = "Echange";
          order.client_id = cart.client_id;
          order.clientRemise = cart.clientRemise;
          order.societe_id = societe_id;
          order.depots_id = currentDepot._id;
          order.user_id = user?._id;

          const obj = {
            ticket: {
              main_order: cart.refundedOrder._id,
              ticket_order: order,
              type: "Total",
              process: cart.operationType,
            },
          };
          const ticketrsp = await dispatch(saveTicketApi(obj));
        } else {
          if (nom && mobile) {
            const clientData = {
              nom,
              prenom,
              email,
              mobile,
              address,
              note,
            };
            try {
              const newClient = await dispatch(createClientsApi(clientData));

              if (newClient?.payload?.success) {
                await handleConfirmOrder(cart, newClient?.payload?.data._id);
                setFacture(true);
                navigate("/commandes");
              }
            } catch (error) {
              console.error("Error submitting the order:", error);
              Swal.fire("Error", "Failed to submit the order.", "error");
            }
          } else {
            Swal.fire("Error", "Please fill all the required fields.", "error");
          }
        }
      }
    });
  };

  const paymentRightBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        paymentRightBarRef.current &&
        !paymentRightBarRef.current.contains(event.target as Node)
      ) {
        if (setVisible) setVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setVisible]);
  useEffect(() => {
    if (societe_id) {
      dispatch(findClientBySocieteApi(societe_id));
      dispatch(findDefaultClientsBySociete(societe_id));
    }
    if (openedOrder) dispatch(findTicketsByOrderApi(openedOrder._id));
    console.log();
    if (currentOrganisation) dispatch(findVendeurs(currentOrganisation));
  }, [dispatch, openedOrder]);

  const [facture, setFacture] = useState(false);

  return (
    <div
      className={
        setVisible
          ? visible === true
            ? "col-right-content-container payment-rightbar displayed"
            : "col-right-content-container payment-rightbar"
          : "fixed-payment-rightbar"
      }
      ref={paymentRightBarRef}
    >
      {ticketId ? (
        <TicketDeCaisse
          ShowModal={facture}
          setShowModal={setFacture}
          givenAmount={tempCash + tempCheque}
          cash={tempCash}
          cheque={tempCheque}
          ticket_id={ticketId}
          order_id={openedOrderId}
        />
      ) : null}

      <div className="widget-message" style={{ padding: "10px" }}>
        <div className="widget-title mb-10">
          <h5 onClick={() => console.log(retrievedOrder)}>Confirmation</h5>
        </div>
      </div>

      <div className="activity-section">
        <div className="widget-todo">
          <div className="todo-content">
            <div className="todo-title">
              <h6>
                <span className="align-self-center">
                  {t("Informations client")}
                </span>
              </h6>
            </div>
          </div>
        </div>
        <div style={{ overflow: "auto", maxHeight: "524px" }}>
          <div className="navbar-item flex-row search-ul navbar-dropdown">
            <div className="nav-item align-self-center search-animated">
              <form onSubmit={onSubmit}>
                <input
                  type="text"
                  className="form-control search-form-control"
                  placeholder="Nom"
                  value={
                    cart && cart.refundedOrder
                      ? cart.refundedOrder.client_id.nom
                      : retrievedOrder?.client_id?.nom
                      ? retrievedOrder?.client_id?.nom
                      : nom
                  }
                  onChange={(e) => setNom(e.target.value)}
                />

                <input
                  type="email"
                  className="form-control search-form-control"
                  placeholder="Email"
                  value={
                    cart && cart.refundedOrder
                      ? cart.refundedOrder.client_id.email
                      : retrievedOrder?.client_id?.email
                      ? retrievedOrder?.client_id?.email
                      : email
                  }
                  onChange={(e) => setEmail(e.target.value)}
                />

                <input
                  type="tel"
                  className="form-control search-form-control"
                  placeholder="Téléphone"
                  value={
                    cart && cart.refundedOrder
                      ? cart.refundedOrder.client_id.mobile
                      : retrievedOrder?.client_id?.mobile
                      ? retrievedOrder?.client_id?.mobile
                      : mobile
                  }
                  onChange={(e) => setMobile(e.target.value)}
                />

                <input
                  type="text"
                  className="form-control search-form-control"
                  placeholder="Adresse de Livraison"
                  value={
                    cart && cart.refundedOrder
                      ? cart.refundedOrder.client_id.address
                      : retrievedOrder?.client_id?.address
                      ? retrievedOrder?.client_id?.address
                      : address
                  }
                  onChange={(e) => setAddress(e.target.value)}
                />

                <textarea
                  className="form-control search-form-control"
                  placeholder="Notes ou Demandes Spéciales"
                  rows={3}
                  value={
                    cart && cart.refundedOrder
                      ? cart.refundedOrder.client_id.note
                      : retrievedOrder?.client_id?.note
                      ? retrievedOrder?.client_id?.note
                      : note
                  }
                  onChange={(e) => setNote(e.target.value)}
                  style={{ width: "100%" }}
                />
                {retrievedOrder && retrievedOrder?.status === "En livraison" ? (
                  <button
                    className={"btn btn-success mb-2" + btnClass}
                    style={{ width: "100%", background: "#009688" }}
                    disabled={!retrievedOrder && cart.items.length === 0}
                    onClick={() => handleRetour()}
                  >
                    {t("Retour")}
                  </button>
                ) : null}
                {(retrievedOrder && retrievedOrder?.status === "Payée") ||
                retrievedOrder?.status === "Retour" ? null : (
                  <button
                    className={"btn btn-success mb-2" + btnClass}
                    style={{ width: "100%", background: "#009688" }}
                    disabled={!retrievedOrder && cart.items.length === 0}
                  >
                    {retrievedOrder && retrievedOrder.status === "En cours"
                      ? t("Livraison")
                      : retrievedOrder &&
                        retrievedOrder.status === "En livraison"
                      ? t("Payer")
                      : t("confirm")}
                  </button>
                )}
              </form>
            </div>
            <hr></hr>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineOrderConfirmation;

import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap';
import _ from "lodash";
import { handleKeyPress } from '../../_App/Helpers/helpers';

export default function PaiementPartielle({t,total_input,onSubmit,list_payement,setlist_payement,list_achat_selected,ShowModal,setShowModal}:any) {
    interface AchatFournisseur {
        [fournisseur_id: string]: any[] 
      }
      const [err, seterr]=useState(false)

    const [list_achat_fournisseur, setlist_achat_fournisseur]=useState<AchatFournisseur>({})
    const sumOfValeur = useMemo(() => {
      return list_payement.reduce((accumulator: any, item: any) => {
            return accumulator + (item.paye || 0);
          }, 0)
        
    }, [list_payement]);
  
    useEffect(() => {
        if (list_achat_selected?.length !== 0) {
          console.log('list_achat_selectedlist_achat_selected',list_achat_selected)
            const groupedByFournisseurs= _.groupBy(list_achat_selected, "fournisseur._id");
            setlist_achat_fournisseur(groupedByFournisseurs)
        }
      }, [list_achat_selected]);
      const setvaluepayer = (fournisseur: string ,achatid: string, valeur: any) => {
        const existingPaymentIndex = list_payement.findIndex((item :any)=> item.achatid === achatid);
    
        if (existingPaymentIndex !== -1) {
            const updatedList = [...list_payement];
            updatedList[existingPaymentIndex].paye = valeur;
            setlist_payement(updatedList);
        } else {
            const data = { fournisseur:fournisseur , achatid: achatid, paye: valeur };
            setlist_payement((prev:any) => [...prev, data]);
        }
    }
    const valideInput=()=>{
      if(sumOfValeur.toFixed(3)===total_input){
        seterr(false)

        setShowModal(false) ;onSubmit()
      }else{
        seterr(true)
      }
    }
    
      const Keys = Object.keys(list_achat_fournisseur);

  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-3"
        dialogClassName="modal-50w"
        backdropClassName="modal-backdrop-3"
      >
        <Modal.Header closeButton>
          <Modal.Title className="c-primary">{t("Paiement")} </Modal.Title>
        </Modal.Header>
       <Modal.Body>
        <div className='text-center'>
        <strong >{total_input}</strong>
        
        </div>
        <div className='text-center'>
        <strong >{sumOfValeur.toFixed(3)}</strong>
        {err && <p className='color_red'>{t('Le total des montants ne correspond pas au montant de paiement déclaré')}</p>}
        
        </div>
            <div >
            
            {Keys.map((key) => (
              <React.Fragment key={key}>

                <strong>{list_achat_fournisseur[key][0].fournisseur?.libelle}</strong>
                {list_achat_fournisseur[key].map((item: any) => (
                 <div className='row'> 
                    <div className=' col-3'> 
                    {item.achat.refAchat}
                    </div>
                    <div className=' col-3'> 
                    Montant a payer {(item.achat.total_net_payer - item.achat.payer).toFixed(3)}
                    </div>
                    <div className=' col-3'>
                        <input type='text' 
                           className="form-control search-form-control ml-lg-auto"
                           placeholder="montant "
                           onChange={(e)=>setvaluepayer(key,item.achat._id , parseFloat(e.target.value)) }
                           onKeyPress={handleKeyPress}
                           />
                           
                    </div>
                    <div className=' col-3'>
                       Reste a payer {((item.achat.total_net_payer - item.achat.payer)-list_payement.find((element:any) => element.achatid ===item.achat._id )?.paye||(item.achat.total_net_payer - item.achat.payer)).toFixed(3)}
                           
                    </div>
                 </div>
                ))}
              </React.Fragment>
            ))}
            </div>
       </Modal.Body>

        <Modal.Footer>
          <>
            <button type="reset" className="btn btn-primary-app" onClick={()=>{setlist_payement([]) ;setShowModal(false)}}>
              Annuler
            </button>
            <button  className="btn btn-primary-app" onClick={valideInput} >
              Enregistrer
            </button>
          </>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

import React, { useState } from "react";
import Filtre from "../../Stock/Filtre";

export default function NonVenduFiltre({
  t,
  FiltreData,
  setFiltreData,
  listorganisation,
  listDepots,
  find,
 
  selectedCarte,
  setSelectedCarte,
  selectedCategorie,
  setSelectedCategorie,
  setSelectedSousCategorie,
  categoriesOptions,
  setcategoriesdOptions,
  selectedSousCategorie,
  setsouscategoriesdOptions,
  selections,
  setSelections,
  souscategoriesOptions
}: any) {
  const setkeyword = (value: any) => {
    setFiltreData({ ...FiltreData, keyword: value });
  };


  return (
    <div className=" row  bg-white" style={{ padding: "20px" }}>
      <div className="col-3">
        <label>{t("Date debut")}</label>
        <input
          type="date"
          className="form-control "
          value={FiltreData.date_debut}
          onChange={(e) =>
            setFiltreData({ ...FiltreData, date_debut: e.target.value })
          }
        />
      </div>

      <div className="col-3">
        <label>{t("Date fin")}</label>
        <input
          type="date"
          className="form-control "
          value={FiltreData.date_fin}
          onChange={(e) =>
            setFiltreData({ ...FiltreData, date_fin: e.target.value })
          }
        />
      </div>
      <div className="col-3">
        <label>{t("Organisation")}</label>
        <select
          className="form-control"
          onChange={(e) =>
            setFiltreData({ ...FiltreData, organisation: e.target.value })
          }
          value={FiltreData.organisation}
        >
          {listorganisation &&
            listorganisation?.map((item: any, index: number) => (
              <option key={index} value={item._id}>
                {item?.nom}
              </option>
            ))}
        </select>
      </div>
      <div className="col-3">
        <label>{t("Depots")}</label>
        <select
          className="form-control"
          onChange={(e) =>
            setFiltreData({ ...FiltreData, depot: e.target.value })
          }
          value={FiltreData.depot}
        >
          {listDepots[FiltreData.organisation] &&
            listDepots[FiltreData.organisation]?.map(
              (item: any, index: number) => (
                <option key={index} value={item._id}>
                  {item?.libelle}
                </option>
              )
            )}
        </select>
      </div>

      <Filtre
        handleSearch={find}
        setkeyword={setkeyword}
        t={t}
        searchfiltre={find}
        selectedCarte={selectedCarte}
        setSelectedCarte={setSelectedCarte}
        selectedCategorie={selectedCategorie}
        setSelectedCategorie={setSelectedCategorie}
        selectedSousCategorie={selectedSousCategorie}
        setSelectedSousCategorie={setSelectedSousCategorie}
        categoriesOptions={categoriesOptions}
        setcategoriesdOptions={setcategoriesdOptions}
        souscategoriesOptions={souscategoriesOptions}
        setsouscategoriesdOptions={setsouscategoriesdOptions}
        selections={selections}
        setSelections={setSelections}
      />
    </div>
  );
}

import React, { Suspense, lazy, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import styled from "styled-components";
import ParametrageModal from "../../Components/AppParametrages/Parametrages/ParametrageModal";
import {
  ActiveMenu,
  findParametragesMenusApi,
  updatePositionMenusApi,
} from "../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import AppMenuItemsType from "../../_App/Types/Entites/AppMenus/AppMenuItems";
import AppMenuParametrageType from "../../_App/Types/Entites/AppMenus/AppMenuParametrageType";
import _ from "lodash";
import { logoutApi } from "../../_App/Redux/Slices/Auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MenuStyled = styled.div<{
  $compactmode: boolean;
  $menu: AppMenuParametrageType | null;
}>`
  position: relative;
  width: 150px;
  height: 100%;
  bottom: 0px;
  top: 0px;

  div.sidebar-wrapper {
    background: ${({ $menu }) =>
      $menu ? $menu?.menuPosition?.bgColor : "#e0f0ef"};
    width: ${(props) => (props.$compactmode ? "150px" : "1100px")};
    height: 100%;
    bottom: 0px;
    top: 0px;
    left: ${(props) =>
      props.$compactmode ? "calc(100% - 145px)" : "calc(100% - 1100px);"};
    border-top-right-radius: 0px;
    background: #f0e0e9;
  }
  div.sidebar-wrapper > nav#compactSidebar {
    position: relative;
    width: ${(props) => (props.$compactmode ? "150px" : "1100px")};
    height: 100%;
    bottom: 0px;
    top: 0px;
    right: 0px;
    border-top-right-radius: 0px;
    display: flex;
    justify-content: center;
    flex: auto;
    flex-direction: column;
  }
  div.sidebar-wrapper > nav#compactSidebar > ul.menu-categories {
    position: relative;
    display: flex;
    justify-content: start;
    align-content: start;
    width: 100%;
    height: 91%;
    padding: 10px;
    flex-wrap: wrap;
    flex-direction: column;
  }
  div.sidebar-wrapper > nav#compactSidebar > ul.menu-categories-right {
    display: flex;
    justify-content: start;
    align-content: start;
    width: 100%;
    height: 20%;
    padding: 5px;
    flex-wrap: wrap;
    flex-direction: column;
  }
  div.sidebar-wrapper > nav#compactSidebar > ul.menu-categories-right:before {
    content: "";
    border-top: 2px solid white;
    border-right: none;
    position: absolute;
    left: 5%;
    top: 3px;
    height: 3px;
    width: 90%;
  }

  div.sidebar-wrapper > nav#compactSidebar > ul.menu-categories > li.menu {
    position: relative;
    display: flex;
    flex: auto;
    height: 110px;
    width: 130px;
  }
  div.sidebar-wrapper
    > nav#compactSidebar
    > ul.menu-categories-right
    > li.menu {
    position: relative;
    display: flex;
    flex: auto;
    height: 115px;
    width: 130px;
  }
  @media (max-width: 769px) {
    ul.menu-categories-right > li.menu {
      width: 120px !important;
    }
  }

  div.sidebar-wrapper
    > nav#compactSidebar
    > ul.menu-categories
    > li.menu
    > a.menu-toggle {
    width: 120px;
  }
  div.sidebar-wrapper
    > nav#compactSidebar
    > ul.menu-categories
    > li.menu
    > a.menu-toggle
    > span.badge {
    position: absolute;
    top: 12px;
    left: 9px;
    border-radius: 50px;
    height: 20px;
    width: 20px;
  }

  .fs-25 > span {
    font-size: 25px !important;
  }

  .positions {
    width: 100%;
    clear: both;
  }
  .apos {
    background: #a3cfc4 !important;
  }
  .aposactive{
    background: #e9b1cf!important;
  }
  .btn-deconnexion {
    background: #9bd0f9 !important;
  }
`;

const MenuRight: React.FC = () => {
  const dispatch = useAppDispatch();

  const [compactMode, setCompactMode] = useState(true);
  const [showModalParametrageMenus, setShowModalParametrageMenus] =
    useState(false);
    const navigate= useNavigate()
  const { t } = useTranslation();

  const Menu: AppMenuParametrageType | null = useAppSelector(ActiveMenu);

  useEffect(() => {
    dispatch(findParametragesMenusApi(null));
  }, [dispatch]);

  const Loader = () => <div>Loading...</div>;
  const [LoadedComponent, setLoadedComponent] = useState<
    React.ComponentType<any>
  >(lazy(() => import(`../../LazyLoading/LazyDefault`)));
  const [ShowModal, setShowModal] = useState(true);
  const loadComponent = (componentName: string | null | undefined) => {
    setShowModal(true);
    if (componentName) {
      const LoadedComponent = lazy(
        () => import(`../../LazyLoading/${componentName}`)
      );
      setLoadedComponent(LoadedComponent);
    }
  };

  return (
    <MenuStyled $compactmode={compactMode} $menu={Menu}>
      <div>
        <Suspense fallback={<Loader />}>
          <LoadedComponent ShowModal={ShowModal} setShowModal={setShowModal} />
        </Suspense>
      </div>

      <div className="sidebar-wrapper sidebar-theme">
        {/* <div className="theme-logo">
          <a href="index.html">
            <img src="assets/assets/img/90x90.jpg" className="navbar-logo" alt="logo" />
            <span className="admin-logo">Clediss<span /></span>
          </a>
        </div> */}
        {/* <div className="sidebarCollapseFixed">
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1={19} y1={12} x2={5} y2={12} /><polyline points="12 19 5 12 12 5" /></svg>
        </div> */}
        <nav id="compactSidebar">
          <ul className="menu-categories">
            {Menu &&
              Menu.menuItems.map((item: AppMenuItemsType, index: number) => (
                <li className="menu menu_items" key={index}>
                  {item.link ? (
                    <a
                      href={item.link ? item.link : "#"}
                      className="menu-toggle"
                      style={{
                        backgroundColor: item.bgColor,
                        border: `2px solid ${item.color}`,
                      }}
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          {item.type_icon == "icone" ? (
                            <i
                              className={item.icon}
                              style={{ color: item.color }}
                            />
                          ) : (
                            <span
                              className="fs-25"
                              style={{ color: item.color }}
                            >
                              {item.ordre + 1}
                            </span>
                          )}
                        </div>
                        <span style={{ color: item.color }}>
                          {item.libelle}
                        </span>
                      </div>
                    </a>
                  ) : (
                    <a
                      href="#"
                      className="menu-toggle"
                      style={{
                        backgroundColor: item.bgColor,
                        border: `2px solid ${item.color}`,
                      }}
                      onClick={(e) => loadComponent(item.componentName)}
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          {item.type_icon == "icone" ? (
                            <i
                              className={item.icon}
                              style={{ color: item.color }}
                            />
                          ) : (
                            <span
                              className="fs-25"
                              style={{ color: item.color }}
                            >
                              {item.ordre + 1}
                            </span>
                          )}
                        </div>
                        <span style={{ color: item.color }}>
                          {item.libelle}
                        </span>
                      </div>
                    </a>
                  )}
                </li>
              ))}

      
           
           
       
</ul>

          <ul className="menu-categories menu-categories-right">
            <li className="menu">
          
              {compactMode ? (
                <a
                  key={2}
                  data-active="false"
                  className="menu-toggle"
                  onClick={(e) => setCompactMode(false)}
                >
                  <div className="base-menu">
                    <div className="base-icons">
                      <i className="fas fa-chevron-left"></i>
                    </div>
                    <span>Plus</span>
                  </div>
                </a>
              ) : (
                <a
                  key={1}
                  data-active="false"
                  className="menu-toggle"
                  onClick={(e) => setCompactMode(true)}
                >
                  <div className="base-menu">
                    <div className="base-icons">
                      <i className="fas fa-chevron-right"></i>
                    </div>
                    <span>Moins</span>
                  </div>
                </a>
              )}
            </li>
            <li className="menu">
              <a
                href="#more"
                data-active="false"
                className="menu-toggle"
                onClick={(e) => navigate('/')}
              >
                <div className="base-menu">
                  <div className="base-icons">
                  <i className="fas fa-chart-line"></i>
                  </div>
                  <span>{t('Dashboard')}</span>
                </div>
              </a>
            </li>
            <li className="menu">
              <a
                href="#more"
                data-active="false"
                className="menu-toggle"
                onClick={(e) => setShowModalParametrageMenus(true)}
              >
                <div className="base-menu">
                  <div className="base-icons">
                    <i className="fas fa-cogs"></i>
                  </div>
                  <span>Paramétres</span>
                </div>
              </a>
            </li>

            {Menu && !_.isUndefined(Menu._id) && (
              <>
                {/* <li className="text-center positions">Position de Menu : </li> */}

                <li className="menu">
                  <a
                    href="#more"
                    data-active="false"
                    className="menu-toggle apos"
                    onClick={(e) => {
                      if (Menu._id) {
                        dispatch(
                          updatePositionMenusApi({
                            _id: Menu._id,
                            position: "left",
                          })
                        );
                      }
                    }}
                  >
                    <div className="base-menu">
                      <div className="base-icons">
                        <i className="fas fa-arrow-left"></i>
                      </div>
                      <span>A Gauche</span>
                    </div>
                  </a>
                </li>
                <li className="menu">
                  <a
                    href="#more"
                    data-active="false"
                    className="menu-toggle aposactive"
                    onClick={(e) => {
                      if (Menu._id) {
                        dispatch(
                          updatePositionMenusApi({
                            _id: Menu._id,
                            position: "right",
                          })
                        );
                      }
                    }}
                  >
                    <div className="base-menu">
                      <div className="base-icons">
                        <i className="fas fa-arrow-right"></i>
                      </div>
                      <span>A droite</span>
                    </div>
                  </a>
                </li>

                <li className="menu">
                  <a
                    href="#more"
                    data-active="false"
                    className="menu-toggle apos"
                    onClick={(e) => {
                      if (Menu._id) {
                        dispatch(
                          updatePositionMenusApi({
                            _id: Menu._id,
                            position: "down",
                          })
                        );
                      }
                    }}
                  >
                    <div className="base-menu">
                      <div className="base-icons">
                        <i className="fas fa-arrow-down"></i>
                      </div>
                      <span>En Bas</span>
                    </div>
                  </a>
                </li>

                <li className="menu">
                  <a
                    href="#more"
                    data-active="false"
                    className="menu-toggle apos"
                    onClick={(e) => {
                      if (Menu._id) {
                        dispatch(
                          updatePositionMenusApi({
                            _id: Menu._id,
                            position: "up",
                          })
                        );
                      }
                    }}
                  >
                    <div className="base-menu">
                      <div className="base-icons">
                        <i className="fas fa-arrow-up"></i>
                      </div>
                      <span>En Haut</span>
                    </div>
                  </a>
                </li>
              </>
            )}
           
          </ul>
          <ul className="menu-categories menu-categories-right">
          <li className="menu">
              <a
                href="#deconnection"
                data-active="false"
                className="menu-toggle btn-deconnexion"
                onClick={(e) => {
                  dispatch(logoutApi());
                }}
              >
                <div className="base-menu">
                  <div className="base-icons">
                    <i className="fas fa-power-off"></i>
                  </div>
                  <span>{t('Déconnexion')}</span>
                </div>
              </a>
            </li>
                </ul>
        </nav>
      </div>

      {/* Parametrage Menus Form */}
      <ParametrageModal
        showModal={showModalParametrageMenus}
        setShowModal={setShowModalParametrageMenus}
      />
    </MenuStyled>
  );
};

export default MenuRight;

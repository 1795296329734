import React from "react";
import { Modal } from "react-bootstrap";
import Zoom from "react-medium-image-zoom";

export default function ArticleListReference({
  t,
  ShowModal,
  setShowModal,
  selectAricle,
  list,
  reference,
}: any) {
  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-50w"
      >
        <Modal.Header>
          <Modal.Title className="c-primary">
            {t("Article de reference")} {reference}
            {}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list">
            <div className="row layout-spacing">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <th colSpan={2}>{t("Article")}</th>
                    <th>{t("Prix Vente")}</th>
                  </thead>

                  <tbody>
                    {list &&
                      list.map((article: any) => (
                        <tr>
                          <td>
                            <Zoom>
                              <img
                                alt=""
                                src={
                                  article.image && article.image[0]
                                    ? process.env.REACT_APP_API_PUBLIC_URL +
                                      "article/" +
                                      article.image[0]
                                    : "/assets/assets/img/no-image.jpg"
                                }
                                width="50"
                              />
                            </Zoom>
                          </td>
                          <td onClick={() => selectAricle(article)}>
                            <p className="lien-bleu-souligne ">
                              {article?.titre?.fr}
                            </p>
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid #eff1f1",
                            }}
                          >
                            {article?.prix_vente_ttc.toFixed(3)}
                          </td>
                        </tr>
                      ))}
                    <tr></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { DepotsType } from "../../../Types/Entites/DepotsType";
import _ from "lodash";
/**
 * create Depots function
 * @private
 */
export const createDepotsApi = createAsyncThunk(
  "depots/createDepotsApi",
  async (data: DepotsType, { rejectWithValue }) => {
    try {
      const response = await axios.post("depots/createDepotsApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * Get list of depots.
 * @private
 */
export const findDepotsBySocieteApi = createAsyncThunk(
  "depots/findDepotsBySocieteApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`depots/findDepotsBySocieteApi/${id}`);

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get   depots par defaut.
 * @private
 */
export const findDepotsDefautByOrganisationApi = createAsyncThunk(
  "depots/findDepotsDefautByOrganisationApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `depots/findDepotsDefautByOrganisationApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      // console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findDepotsByOrganisationsApi = createAsyncThunk(
  "depots/findDepotsByOrganisationsApi",
  async (data:any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `depots/findDepotsByOrganisationsApi`,data
      );
      return response.data;
    } catch (error: any) {
      // console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * update depots.
 * @private
 */

export const updateDepotsApi = createAsyncThunk(
  "depots/updateDepotsApi",
  async (data: DepotsType, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put("depots/updateDepotsApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * delete depots.
 * @private
 */

export const deleteDepotsApi = createAsyncThunk(
  "depots/deleteDepotsApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`depots/deleteDepotsApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface ProductColorState {
  listDepot: DepotsType[];
  listDepotByOrganisation: { [organisation: string]: any[] };
  depots_defaut: any;
}
const initialState: ProductColorState = {
  listDepot: [],
  listDepotByOrganisation: {},
  depots_defaut: {},
};

export const DepotSlice = createSlice({
  name: "depotsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createDepotsApi.fulfilled, (state, action) => {
      state.listDepot.splice(0, 0, action.payload.data);
    });

    builder.addCase(findDepotsBySocieteApi.fulfilled, (state, action) => {
      const groupedByOrganisation = _.groupBy(
        action.payload,
        "organisation_id"
      );

      state.listDepot = action.payload;
      state.listDepotByOrganisation = groupedByOrganisation;
    });

    builder.addCase(updateDepotsApi.fulfilled, (state, action) => {
      const updated_depots = action.payload.data;
      var index = _.findIndex(state.listDepot, {
        _id: updated_depots._id,
      });
      // Replace item at index using native splice
      state.listDepot.splice(index, 1, updated_depots);
    });

    builder.addCase(deleteDepotsApi.fulfilled, (state, action) => {
      const deleted_depots = action.payload.data;
      state.listDepot = state.listDepot.filter(
        (item) => item._id !== deleted_depots._id
      );
    });

    builder.addCase(
      findDepotsDefautByOrganisationApi.fulfilled,
      (state, action) => {
        state.depots_defaut = action.payload;
      }
    );
    builder.addCase(
      findDepotsByOrganisationsApi.fulfilled,
      (state, action) => {
        state.listDepot = action.payload;
      }
    );

    // builder.addCase(findDepotsDefautByOrganisationApi.fulfilled, (state, action) => {

    //   state.depots_defaut = action.payload

    // });
  },
});

export const ListDepots = (state: RootState) => state.depots.listDepot;
export const ListDepotByOrganisation = (state: RootState) =>
  state.depots.listDepotByOrganisation;
export const DepotDefautOrganisation = (state: RootState) =>
  state.depots.depots_defaut;

export default DepotSlice.reducer;
